import React from "react";
import Table from "templates/Table";
import { Query } from 'react-apollo';
import gql from 'graphql-tag';


const GET_PRODUCT_DOCS = gql`
  query($first: Int) {
    product_docs(first: $first) {
      edges {
        node {
          id 
          title
          slug
          featuredImage {
            sourceUrl
          }
          acf {
            subtitle
            description
          }
        }
      }
    }
  }
`;


class ProductDocs extends React.Component {
  render() {
    return ( 
      <div>
        <Query query={GET_PRODUCT_DOCS} variables={{ first: 100 }}>
          {({ loading, error, data }) => {
            if (loading) return <div className="loader" />;
            if (error) return <p>Error :(</p>;
            if (!data.product_docs.edges.length) return <p>No matching posts found.</p>;
            let items = data.product_docs.edges;
            return <Table data={items} logProps={this.props.logProps} />
          }}
        </Query>
      </div>
    )
  }
}

export default ProductDocs;
