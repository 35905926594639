import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import CookiesPolicy from 'components/CookiesPolicy';


class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.navClose = this.navClose.bind(this);
  }

  navToggle() {
    document.body.classList.toggle('navIn');
  }
  navClose() {
    document.body.classList.remove('navIn');
  }

  // 资料中心 会议中心 Netiser 顶部导航点击返回无效的修复
  // 服务知识库 可不加只是为了统一效果
  refresh(url){
    window.location.href = url;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.navClose();
    }
  }

  // PASTING DIRECTIONS:
  // close single img and input tags
  // remove comments
  // convert styles to js object
  // convert class= to className=
  // convert open netis links from a to NavLink
  render() {

    let userLevel;
    const getUser = localStorage.getItem('loggedInUser');
    if (getUser) {
      userLevel = JSON.parse(getUser).userLevel;
    }
    
    if(!localStorage.getItem('cookies_policy')){
      setTimeout(() => {
        document.querySelector('.cookies_policy').classList.add('open');
      }, 1000);
    }
    
    return (
      <>
      <header id="main-header" data-height-onload="80" data-height-loaded="true" data-fixed-height-onload="80" className="et-fixed-header" style={{ top: '0px' }}>
        <div className="container clearfix et_menu_container">
          <div className="logo_container">
            <span className="logo_helper"></span>
            <a href="https://www.netis.com/">
              <img src="/static/img/netis_logo.svg" alt="天旦Netis" id="logo" width="113" height="24" data-height-percentage="54" data-actual-width="600" data-actual-height="130" />
            </a>
          </div>
          <div id="et-top-navigation" data-height="66" data-fixed-height="40" style={{ paddingLeft: '196px' }}>
            <nav id="top-menu-nav">
                <ul id="top-menu" className="nav">


                    <li id="menu-item-241" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-241">
                        <a href="https://www.netis.com">首页</a>
                    </li>

                    <li id="menu-item-524" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-241">
                        <NavLink to="/datacenter" onClick={this.refresh.bind(this,'/datacenter')}>资料中心</NavLink>
                    </li>
                    <li id="menu-item-241" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-241">
                        <NavLink to="/college" onClick={this.refresh.bind(this,'/college')}>会议中心</NavLink>
                    </li>
                    <li id="menu-item-241" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-241">
                        <NavLink to="/knowledgebase" onClick={this.refresh.bind(this,'/knowledgebase')}>服务知识库</NavLink>
                    </li>
                    {(userLevel === "employee") && 
                      <li id="menu-item-241" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-241">
                        <NavLink to="/netiser" onClick={this.refresh.bind(this,'/netiser')}>Netiser</NavLink>
                      </li>
                    }
                    {/* 暂时不上 open 的菜单栏 这个页面将用于 demo 对接 */}
                    {/*                     
                    {(!localStorage.getItem('loggedInUser')) && 
                      <li><NavLink to="/user/new-account">登录或注册</NavLink></li>
                    }
                    */}
                </ul>
            </nav>




            <div id="et_mobile_nav_menu">
              <div className="mobile_nav closed">
                <span className="select_page">Select Page</span>
                <span className="mobile_menu_bar mobile_menu_bar_toggle" onClick={this.navToggle}></span>
                <ul id="mobile_menu" className="et_mobile_menu">
                  <li id="menu-item-229" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-229 et_first_mobile_item"><a href="https://wfwfww.netis.com/products/bpc/">产品</a>
                    <ul className="sub-menu">
                      <li id="menu-item-723" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-723"><a href="https://www.netis.com/products/bpc/">业务性能管理</a>
                        <ul className="sub-menu">
                          <li className="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://www.netis.com/products/bpc/">业务性能管理</a></li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://www.netis.com/products/teamvision2/">TeamVision 2</a></li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://www.netis.com/products/netvision/">NetVision</a></li>
                        </ul>
                      </li>
                      <li id="menu-item-230" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-230"><a href="https://www.netis.com/products/npm/">网络性能管理</a></li>
                      <li id="menu-item-877" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-877"><a href="https://www.netis.com/products/dbcube/">数据库性能管理</a></li>
                      <li id="menu-item-1285" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1285"><a href="https://www.netis.com/products/appmall/">互联数据应用平台</a></li>
                      <li id="menu-item-1284" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1284"><a href="https://www.netis.com/products/liuxin/">流芯互联数据引擎</a></li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1284"><a href="https://www.netis.com/products/packet-agent/">Packet Agent</a></li>
                    </ul>
                  </li>
                  <li id="menu-item-133" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-133"><a href="https://www.netis.com/solutions/security/">解决方案</a>
                    <ul className="sub-menu">
                      <li id="menu-item-879" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-824 current_page_item menu-item-879"><a href="https://www.netis.com/solutions/cloud-performance-monitoring/" aria-current="page">云环境业务性能监控解决方案</a></li>
                      <li id="menu-item-878" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-878"><a href="https://www.netis.com/solutions/city-commercial-bank/">城市商业银行一体化运维解决方案</a></li>
                      <li id="menu-item-956" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-956"><a href="https://www.netis.com/solutions/rural-credit-cooperatives/">农信社、农商行业务性能监控解决方案</a></li>
                      <li id="menu-item-880" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-880"><a href="https://www.netis.com/solutions/insurance/">保险智能运维解决方案</a></li>
                      <li id="menu-item-131" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-131"><a href="https://www.netis.com/solutions/security/">证券智能运维解决方案</a></li>
                    </ul>
                  </li>
                  <li id="menu-item-231" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-231"><a href="https://www.netis.com/partners/">渠道合作</a></li>
                  <li id="menu-item-241" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-241"><a href="https://www.netis.com/customer-service/">客户服务</a></li>
                  <li id="menu-item-240" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-240"><a href="https://www.netis.com/about/about-us/">关于我们</a>
                    <ul className="sub-menu">
                      <li id="menu-item-242" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-242"><a href="https://www.netis.com/about/about-us/">关于天旦</a></li>
                      <li id="menu-item-334" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-334"><a href="https://www.netis.com/about/news/">新闻中心</a></li>
                      <li id="menu-item-656" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-656"><a href="https://www.netis.com/about/jobs/">招贤纳士</a></li>
                      <li id="menu-item-243" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-243"><a href="https://www.netis.com/about/contact/">联系我们</a></li>
                    </ul>
                  </li>
                  <li id="menu-item-524" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-524"><NavLink to="/datacenter" onClick={this.refresh.bind(this,'/datacenter')}>资料中心</NavLink></li>
                  <li id="menu-item-525" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-525"><NavLink to="/college" onClick={this.refresh.bind(this,'/college')}>会议中心</NavLink></li>
                  <li id="menu-item-525" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-525"><NavLink to="/knowledgebase" onClick={this.refresh.bind(this,'/knowledgebase')}>服务知识库</NavLink></li>
                  {(userLevel === "employee") && 
                      <li id="menu-item-525" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-525"><NavLink to="/netiser" onClick={this.refresh.bind(this,'/netiser')}>Netiser</NavLink></li>
                    }
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="et_search_outer">
          <div className="container et_search_form_container">
            {/* <form role="search" method="get" className="et-search-form" action="https://www.netis.com/">
              <input type="search" className="et-search-field" placeholder="Search …" value="" name="s" title="Search for:" />					</form> */}
            <span className="et_close_search_field"></span>
          </div>
        </div>
      </header>
      
      {!localStorage.getItem('cookies_policy')?<CookiesPolicy/>:''}
      </>
    );
  }
}

export default withRouter(Menu);


