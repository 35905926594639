//
import axios from 'axios';
//
import Env from '../config/env.js';
//
import UserModel from '../model/user.js';

//
class TagModel 
{
    //
    static getArticle(tagId) 
    {
        //
        const userId = UserModel.getId();
        const options = {
            'user_id': userId
        };
        //
        // const that = this;
        const url = `${Env.base}/tag/${tagId}`;
        //
        return axios({
            url: url,
            method: 'get',
            params: options
        });
    }

    //
    static getTag(brandId = 0) 
    {
        // const that = this;
        let url = `${Env.base}/tag`;


        if (brandId <= 0) {
            brandId = localStorage.getItem('brand_id');
        }

        if (brandId !== null) {
            url += '?brand_id=' + brandId;
        }
        //
        return axios({
            url: url,
            method: 'get'
        });
    }
}

export default TagModel;