import React from "react";
import { NavLink } from 'react-router-dom';
// import axios from 'axios';
//
import StatisticsModel from 'model/statistics.js';

//
class HomeModel extends React.Component 
{
    //
    constructor(props) 
    {
        //
        super(props);

        //
        // console.log(props);
        const { brandId } = props;
        //
        this.state = {
            brandId: brandId,
            result: {
                a: {
                    count:0,
                    items: [{
                        id:0,
                        title:'#'
                    },{
                        id:1,
                        title:'#'
                    }]
                },
                b: {
                    count:0,
                    items: [{
                        id:0,
                        title:'#'
                    },{
                        id:1,
                        title:'#'
                    }]
                },
                c: {
                    count:0,
                    items: [{
                        id:0,
                        title:'#'
                    },{
                        id:1,
                        title:'#'
                    }]
                },
                d: {
                    count:0,
                    items: [{
                        id:0,
                        title:'#'
                    },{
                        id:1,
                        title:'#'
                    }]
                },
                e: {
                    count:0,
                    items: [{
                        id:0,
                        title:'#'
                    },{
                        id:1,
                        title:'#'
                    }]
                }
            }
        };
    }

    componentWillReceiveProps(props) 
    {
        // console.log('will');
        const { brandId } = props;
        this.change(brandId);
    }

    componentDidMount() 
    {
        const brandId = this.state.brandId;
        this.change(brandId);
    };

    change(brandId) 
    {
        //
        const that = this;

        //
        StatisticsModel.get(brandId)
        .then(res => {
            const result = res.data;
            that.setState({
                result: result
            });
        });
    }

    //
    render() {
        //
        const result = this.state.result;
        // console.log(result);
        //
        return (
            <section className="container-fluid">
                <div className="container">
                    <div className="models d-flex justify-content-between">

                        <div className="model bg-white d-flex justify-content-between">
                            <div className="flex-grow-1">
                            <div className="title">深度知识</div>
                                {result.a.items.map((item) =>
                                    <div key={item.id}>
                                            <NavLink to={'/knowledgebase/article/' + item.title}>{item.title}</NavLink>
                                    </div>
                                )}
                            </div>
                            <div className="count">
                                <div className="ijosf">知识总数</div>
                                <div className="pseff">{result.a.count}</div>
                            </div>
                        </div>

                        <div className="model bg-white d-flex justify-content-between">
                            <div className="flex-grow-1">
                            <div className="title">问题排查</div>
                                {result.b.items.map((item) =>
                                    <div key={item.id}>
                                            <NavLink to={'/knowledgebase/article/' + item.title}>{item.title}</NavLink>
                                    </div>
                                )}
                            </div>
                            <div className="count">
                                <div className="ijosf">知识总数</div>
                                <div className="pseff">{result.b.count}</div>
                            </div>
                        </div>

                    </div>
                    <div className="models d-flex justify-content-between">

                        <div className="model bg-white d-flex justify-content-between">
                            <div className="flex-grow-1">
                            <div className="title">最佳实践</div>
                                {result.c.items.map((item) =>
                                    <div key={item.id}>
                                            <NavLink to={'/knowledgebase/article/' + item.title}>{item.title}</NavLink>
                                    </div>
                                )}
                            </div>
                            <div className="count">
                                <div className="ijosf">知识总数</div>
                                <div className="pseff">{result.c.count}</div>
                            </div>
                        </div>

                        <div className="model bg-white d-flex justify-content-between">
                            <div className="flex-grow-1">
                            <div className="title">配置介绍</div>
                                {result.d.items.map((item) =>
                                    <div key={item.id}>
                                            <NavLink to={'/knowledgebase/article/' + item.title}>{item.title}</NavLink>
                                    </div>
                                )}
                            </div>
                            <div className="count">
                                <div className="ijosf">知识总数</div>
                                <div className="pseff">{result.d.count}</div>
                            </div>
                        </div>

                    </div>
                    <div className="models d-flex justify-content-between">

                        <div className="model bg-white d-flex justify-content-between">
                            <div className="flex-grow-1">
                            <div className="title">经典案例</div>
                                {result.e.items.map((item) =>
                                    <div key={item.id}>
                                            <NavLink to={'/knowledgebase/article/' + item.title}>{item.title}</NavLink>
                                    </div>
                                )}
                            </div>
                            <div className="count">
                                <div className="ijosf">知识总数</div>
                                <div className="pseff">{result.e.count}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default HomeModel;
