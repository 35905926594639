//
import axios from 'axios';
//
import Env from '../config/env.js';
//
import UserModel from '../model/user.js';

//
class CollectModel 
{
    //
    static getArticle(brandId)
    {
        //
        const userId = UserModel.getId();
        const options = {
            'user_id': userId,
            'brand_id': brandId
        };
        //
        // const that = this;
        const url = `${Env.base}/collect`;
        //
        return axios({
            url: url,
            method: 'get',
            params: options
        });
    }
}

export default CollectModel;