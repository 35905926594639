import axios from 'axios'
import React, { Component } from 'react'
import { Field, Form } from 'react-final-form'
import ReactSVG from 'react-svg'
import thisEnv from 'env'


class UserAccessRequestForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  sendEmail(postUser) {
    const { setLoginRoute } = this.props.logProps;

    let url = thisEnv.phpUrl;
    url += '/emails/training-application/email.php';
    axios({
      method: 'post',
      url,
      data: {
        postUser,
        isEmailLive: thisEnv.isEmailLive
      }
    }).then(response => {
      if (response.data === 'success') {
        setLoginRoute('training-application-confirmation');
      }
    });
  }

  render() {
    const { setLoginRoute, loggedInUser } = this.props.logProps;
    const onSubmit = (values) => {
      this.setState({ error: null, loading: true })
      this.sendEmail(values);
    }
    const trainingSubject = document.querySelector('input[name=setTrainingSubject]').value;
    return (
      <div className="form-holder new-account">
        <span className="close-x" onClick={() => setLoginRoute(null)}></span>
        <h4>申请培训</h4>
        <Form
          onSubmit={onSubmit}
          initialValues={{ 
            name: loggedInUser.name,
            company: loggedInUser.company,
            position: loggedInUser.position,
            email: loggedInUser.email,
            mobile: loggedInUser.mobile,
            trainingSubject
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}
            // noValidate
            >
              <Field
                name="name"
                placeholder="姓名*"
                component="input"
                type="text"
                required
              />
              <Field
                name="company"
                placeholder="单位*"
                component="input"
                type="text"
                required
              />
              <Field
                name="position"
                placeholder="职位*"
                component="input"
                type="text"
                required
              />              
              <Field
                name="email"
                placeholder="邮箱*"
                component="input"
                type="email"
                required
              />

              <Field
                name="mobile"
                placeholder="手机*"
                component="input"
                type="text"
                disabled={this.state.awaitingCode}
                required
              />

              <Field
                name="trainingSubject"
                component="input"
                type="hidden"
              />

              <div className={'submit-wrap ' + (this.state.loading ? 'loading' : '')}>
                <span className="loader">
                  <ReactSVG src="/images/ui/loader-form.svg" />
                </span>
                <input
                  type="submit"
                  value="确认"
                  disabled={this.state.loading}
                />
              </div>

            </form>
          )}
        />
      </div>
    )
  }
}

export default UserAccessRequestForm









