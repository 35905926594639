import React from 'react';

const Icon = (props) => {
  const { name } = props;
  let path = `/images/icons/icon-${name}-blue-2-inverse.png`;
  if (name === "lock") {
    path = `/images/icons/icon-${name}-blue-2.png`;
  }
  return (
    <div className="icon">
      <img className="blue-2" src={path} alt="icon" />
      {/* <img className="green" src={`/images/icons/icon-${name}-green.png`} alt="icon" /> */}
    </div>
  )
}

export default Icon;