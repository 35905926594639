import React from "react";
// import { NavLink } from 'react-router-dom';
// import axios from 'axios';
// module
import Subnav from 'pages/summary/module/subnav.js';
// import Item from 'pages/summary/module/item.js';
import Tag from 'pages/summary/module/tag.js';
import Search from 'pages/summary/module/search.js';
import Foot from 'pages/summary/module/foot.js';
import Team from 'pages/summary/module/team.js';
// 
// import User from 'utils/user.js';
// component
import Articles from 'components/summary/articles.js';
import BreadcrumbsComponent from 'components/summary/breadcrumbs.js'; 
//
import ArticleModel from 'model/article.js';
import BrandModel from 'model/brand.js';
import CategoryModel from 'model/category.js';
import BaseView from '../BaseView'

class Category extends BaseView {
    //
    constructor(props) {
        super(props);

        //
        const { match,history } = props.props;
        const brandId = this.brandNameToId(match.params.brand_id);
        const categoryId = this.categoryNameToId(match.params.category_id);

        this.state = {
            brandId: parseInt(brandId),
            categoryId: categoryId,
            //
            articles: [],
            //
            history: history
        };  
    }

    //
    brandNameToId(name) {
        switch (name) {
            //
            case 'bpc':
                return 1;
                break;
            //
            case 'npm':
                return 2;
                break;
            //
            case 'packet-agent':
                return 3;
                break;
            //
            default: 
                return 0;
                break;
        }
    }

    //
    categoryNameToId(name) {
        //
        switch (name) {
            //
            case 'indepth':
                return 1;
                break;
            //
            case 'troubleshooting':
                return 2;
                break;
            //
            case 'practise':
                return 3;
                break;
            //
            case 'configuration':
                return 4;
                break;
            //
            case 'cases':
                return 5;
                break;
            //
            default: 
                return 0;
                break;
        }
    }

    //
    componentWillReceiveProps(props) {
        //
        console.log('componentWillReceivePropscomponentWillReceivePropscomponentWillReceiveProps', props)
        const { match } = props.props;
        const brandId = this.brandNameToId(match.params.brand_id);
        const categoryId = this.categoryNameToId(match.params.category_id);

        if (parseInt(brandId) !== this.state.brandId || categoryId !== this.state.categoryId) {
            this.buryingPoint('天旦Netis | 服务知识库' + this.getTitle(categoryId, parseInt(brandId)));
        }
        //
        this.setState({
            brandId: parseInt(brandId),
            categoryId: categoryId
        });


        this.getArticle(categoryId);
    }

    //
    componentWillMount() {
        // console.log('will mount');
    }

    //
    componentDidMount() {
        let categoryId = this.state.categoryId;
        this.getArticle(categoryId);
        this.buryingPoint('天旦Netis | 服务知识库' + this.getTitle(this.state.categoryId, this.state.brandId));
    }

    getArticle(categoryId) {
        const brandId = this.state.brandId;
        /*
        if (categoryId > 5) {
            categoryId = 0;
        }
        */
        const that = this;
        ArticleModel.get(brandId,categoryId)
        .then(res => {
            that.setState({
                articles: res.data
            });
        });
    }

    //
    componentWillUnmount() {
        // console.log('will unmount');
    }

    getTitle(categoryId, brandId) {
        var title = BrandModel.getName(brandId) + CategoryModel.getName(categoryId);
        return title;
    }

    //
    render() {
        //
        const articles = this.state.articles;
        
        const categoryId = this.state.categoryId; 
        const brandId = this.state.brandId;  
        const title = this.getTitle(categoryId, brandId);
        const history = this.state.history;
        //
        const { setLoginRoute } = this.props;

        //
        return ( 
            <section>
                <Search history={history} title={title} />
                <Subnav brandId={brandId} categoryId={categoryId} />
                <BreadcrumbsComponent title={title} />
                <div className="container-fluid">
                    <div className="container">
                        <div className="primary">
                            <div className="content bg-white">
                                <div className="articles">
                                    <Articles history={history} articles={articles} setLoginRoute={setLoginRoute} />
                                </div>
                            </div>
                            <div className="side">
                                <Tag />
                                <Team />
                            </div>
                        </div>
                    </div>
                </div>


                <Foot />
            </section>
        )
    }
}

export default Category;
