import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import NewAccountFormData from 'pages/User/NewAccountFormData';
import LoginForm from 'pages/User/LoginForm';

import NotFoundPage from 'pages/NotFoundPage';

import ScrollToTop from 'components/ScrollToTop'


class Index extends React.Component {

  render() {

    let baseUrl = this.props.match.url;
    if (baseUrl.endsWith('/')) {
      baseUrl = baseUrl.slice(0, -1);
    }

    const { logProps } = this.props;

    return (
      <div>

        <BrowserRouter>
          <ScrollToTop>
            {/* <div className="main user v1"> */}
            <div className="main passport">
              <Switch>
                <Route path={`${baseUrl}/new-account`} exact={true} render={(props) => <NewAccountFormData logProps={logProps} />} />
                <Route path={`${baseUrl}/log-in`} exact={true} render={(props) => <LoginForm logProps={logProps} />} />
                <Route component={NotFoundPage} />
              </Switch>
            </div>
          </ScrollToTop>
        </BrowserRouter>

      </div>
    )
  }
}


export default Index;
