import React from "react";
import { BrowserRouter, Switch, Route, NavLink } from "react-router-dom";

import LandingPage from 'pages/NetisCollege/LandingPage';
import Training from 'pages/NetisCollege/Training';
import ProductDocs from 'pages/NetisCollege/ProductDocs';
import VodkaSpace from 'pages/NetisCollege/VodkaSpace';
import ResearchInstitute from 'pages/NetisCollege/ResearchInstitute'; 

//
// import Lib from 'pages/NetisCollege/Lib';
// import Knowledge from 'pages/NetisCollege/Knowledge';

import InnerPage from 'pages/InnerPage';
import NotFoundPage from 'pages/NotFoundPage';

import LoginOverlay from 'forms/DataCenter/LoginOverlay'
import Status from 'components/Status'


class Index extends React.Component {

  render() {

    let baseUrl = this.props.match.url;
    if (baseUrl.endsWith('/')) {
      baseUrl = baseUrl.slice(0, -1);
    }

    const { logProps } = this.props;

    return (
      <div className="section college">
        <LoginOverlay logProps={logProps} />
        <BrowserRouter>
          <div className="navbar">
            <div className="inner">
              <div className="menu">
                <NavLink to={`${baseUrl}/training`}><span>使用培训</span></NavLink>
                <NavLink to={`${baseUrl}/research-institute`}><span>回看研讨会</span></NavLink>
              </div>
              <Status addClass="desktop" logProps={logProps} />
            </div>
          </div>
        
          <Status addClass="mobile" logProps={logProps} />
          <div className="main">
            <Switch>
              <Route path={baseUrl} exact={true} render={(props) => <LandingPage logProps={logProps} />} />
              <Route path={`${baseUrl}/training`} exact={true} render={(props) => <Training logProps={logProps} />} />
              <Route path={`${baseUrl}/training/:slug`} render={(props) => <InnerPage logProps={logProps} />} />
              <Route path={`${baseUrl}/product-docs`} exact={true} render={(props) => <ProductDocs logProps={logProps} />} />
              <Route path={`${baseUrl}/product-docs/:slug`} render={(props) => <InnerPage logProps={logProps} />} />
              <Route path={`${baseUrl}/vodka-space`} exact={true} render={(props) => <VodkaSpace logProps={logProps} />} />
              <Route path={`${baseUrl}/vodka-space/:slug`} render={(props) => <InnerPage logProps={logProps} />} />
              <Route path={`${baseUrl}/research-institute`} exact={true} render={(props) => <ResearchInstitute logProps={logProps} />} />
              <Route path={`${baseUrl}/research-institute/:slug`} render={(props) => <InnerPage logProps={logProps} />} />
              <Route component={NotFoundPage} />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    )
  }
}

export default Index;
