import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFoundPage from 'pages/NotFoundPage';

import Home from 'pages/summary/home.js';
import Category from 'pages/summary/category.js';
import Article from 'pages/summary/article.js';
import Tag from 'pages/summary/tag.js';
import Filter from 'pages/summary/filter.js';
import Collect from 'pages/summary/collect.js';
import Newest from 'pages/summary/newest.js';
import Hot from 'pages/summary/hot.js';
//
import Status from 'components/Status';
import LoginOverlay from 'forms/DataCenter/LoginOverlay';
//
import { Helmet } from 'react-helmet-async';

//
class Summary extends React.Component {
    //
    constructor(props) {
        super(props);

        // 
        // console.log('index  construct');
        // console.log(props);

        const { history,location } = props;
        this.state = {
            history: history,
            location: location
        };
    }

    //
    componentWillMount() {
        // console.log('will mount');
    }

    //
    componentDidMount() {
        // console.log('componentDidMount');
    }

    //
    componentWillUnmount() {
        // console.log('will unmount');
    }

    //
    render() {
        //
        let baseUrl = this.props.match.url;
        if (baseUrl.endsWith('/')) {
          baseUrl = baseUrl.slice(0, -1);
        }
    
        // const history = this.state.history;
        // const location = this.state.location;
        const { logProps } = this.props;
        const { setLoginRoute } = this.props.logProps;

        //
        return ( 
            <div> 
                <ThisHelmet />
                <LoginOverlay logProps={logProps} />
                <div className="container-fluid bg-white">
                    <div className="container">
                        <div className="account text-right pt-2 pb-2">
                            <Status addClass="desktop" logProps={logProps} />
                        </div>
                    </div>
                </div>
                <Switch>
                    <Route path={`${baseUrl}`} exact={true} render={(props) => <Home props={props} setLoginRoute={setLoginRoute} />} />
                    <Route path={`${baseUrl}/article/:id`} exact={true} render={(props) => <Article props={props} setLoginRoute={setLoginRoute} />} />
                    <Route path={`${baseUrl}/category/:brand_id/:category_id`} exact={true} render={(props) => <Category props={props} setLoginRoute={setLoginRoute} />} />
                    <Route path={`${baseUrl}/tag/:id`} exact={true} render={(props) => <Tag props={props} setLoginRoute={setLoginRoute} />} />
                    <Route path={`${baseUrl}/search/:brand_id/:category_id/:keyword`} exact={true} render={(props) => <Filter props={props} setLoginRoute={setLoginRoute} />} />
                    <Route path={`${baseUrl}/collect/:brand_id`} exact={true} render={(props) => <Collect props={props} setLoginRoute={setLoginRoute} />} />
                    <Route path={`${baseUrl}/hot/:brand_id`} exact={true} render={(props) => <Hot props={props} setLoginRoute={setLoginRoute} />} />
                    <Route path={`${baseUrl}/latest/:brand_id`} exact={true} render={(props) => <Newest props={props} setLoginRoute={setLoginRoute} />} />
                    <Route component={NotFoundPage} />
                </Switch>
            </div>
        )
    }
}

const ThisHelmet = (props) => {
//   const title = '天旦Netis | 服务知识库';
  return (
    <Helmet>
      {/* <title>{title}</title> */}
    </Helmet>
  )
}

export default Summary;
