import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Register from 'pages/Passport/Register';
import Login from 'pages/Passport/Login';
// 测试用
import Demo from 'components/Demo';

import NotFoundPage from 'pages/NotFoundPage';

import ScrollToTop from 'components/ScrollToTop'


class Index extends React.Component {

  render() {

    let baseUrl = this.props.match.url;
    if (baseUrl.endsWith('/')) {
      baseUrl = baseUrl.slice(0, -1);
    }

    const { logProps } = this.props;

    return (
      <div>

        <BrowserRouter>
          <ScrollToTop>
            <div className="main passport">
              <Switch>
                <Route path={`${baseUrl}/register`} exact={true} render={(props) => <Register logProps={logProps} />} />
                <Route path={`${baseUrl}/login`} exact={true} render={(props) => <Login logProps={logProps} />} />
                <Route path={`${baseUrl}/demo`} exact={true} render={(props) => <Demo logProps={logProps} />} />
                <Route component={NotFoundPage} />
              </Switch>
            </div>
          </ScrollToTop>
        </BrowserRouter>

      </div>
    )
  }
}


export default Index;
