import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

class LoginConfirmation extends Component {

  render() {
    const logProps = this.props.logProps;
    const { setLoginRoute } = logProps;
    return (
      <div className="form-holder login-confirmation">
        <Redirect to={window.location}/> {/* 登入出权限刷新组件骚操作 */}
        <span className="close-x" onClick={ () => setLoginRoute(null) }></span>
        <h4>成功</h4>
        <p>您已成功登录。</p>
        <div className="submit-wrap">
          <button onClick={ () => { setLoginRoute(null) }}>关闭</button>
        </div>

      </div>
    )
  }
}

export default LoginConfirmation









