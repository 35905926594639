import React from "react";
import Landing from "templates/Landing";



class LandingPage extends React.Component {

  render() {
    return (
      <Landing logProps={this.props.logProps} />
    )
  }
}

export default LandingPage;
