
import React from "react";
import axios from 'axios'
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import thisEnv from 'env'
import { wpToLogInUser } from 'utils/functions'

import DataCenter from 'pages/DataCenter/Index';
import NetisCollege from 'pages/NetisCollege/Index';
import Summary from 'pages/summary/index';
import User from 'pages/User/Index';
import Passport from 'pages/Passport/Index';
import Netiser from 'pages/Netiser/Index';
import NotFoundPage from 'pages/NotFoundPage';
//
import UserModel from 'model/user.js';


class Container extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loginRoute: null,
    };
    this.logIn = this.logIn.bind(this)
    this.logOut = this.logOut.bind(this)
    this.setLoginRoute = this.setLoginRoute.bind(this)
  }
  refreshUser(mobile) {
    let urlCheckMobile = thisEnv.phpUrl + '/wp/checkUser.php';
    urlCheckMobile += '?wpRestAPIUrl=' + thisEnv.wpRestAPIUrl + '&mobile=' + mobile;
    axios.get(urlCheckMobile).then(response => {
      if (response.data.length >= 1) {
        const logInUser = wpToLogInUser(response.data[0]);
        this.logIn(logInUser);
      } 
    })
  }
  componentDidMount() {
    const getUser = localStorage.getItem('loggedInUser');
    if (getUser) {
      let getUserObj = JSON.parse(getUser);
      this.refreshUser(getUserObj.mobile);
    } else {
      this.setState({ loggedInUser: null })
    }
  }
  logIn(user) {
    this.setState({
      loggedInUser: user
    })
    localStorage.setItem('loggedInUser', JSON.stringify(user));

    // obtain vip
    UserModel.processVipStatus();
  }
  logOut() {
    this.setState({
      loggedInUser: null
    })
    this.setLoginRoute('log-out');
    localStorage.removeItem('loggedInUser');
    localStorage.clear();
  }
  setLoginRoute(route, options = {}) {
    if (route === 'log-in') {
      // 埋点：点击注册登录入口
      window.sensors.track('clickEntrance', {
        'referrer_page': window.document.title,
        'source_seat': options['source_seat'] || '由锁定内容触发'
      });
    } else if (route === 'new-account') {
      // 埋点：点击立即注册
      window.sensors.track('clickRegnow');
    }
    this.setState({
      loginRoute: route
    })
  }

  render() {

    const logProps = {
      setLoginRoute: this.setLoginRoute,
      loginRoute: this.state.loginRoute,
      logIn: this.logIn,
      loggedInUser: this.state.loggedInUser,
      logOut: this.logOut
    }

    let userLevel;
    const getUser = localStorage.getItem('loggedInUser');
    if (getUser) {
      userLevel = JSON.parse(getUser).userLevel;
    }

    return (
        <div id="container">
            <Switch location={this.props.location}>
                <Route exact path="/" render={() => (<Redirect to="/datacenter"/>)}/>
                <Route path="/datacenter" render={(props) => <DataCenter {...props} logProps={logProps} />} />
                <Route path="/college" render={(props) => <NetisCollege {...props} logProps={logProps} />} />
                <Route path="/knowledgebase" render={(props) => <Summary {...props} logProps={logProps} />} />
                {(userLevel === "employee") && 
                  <Route path="/netiser" render={(props) => <Netiser {...props} logProps={logProps} />} />
                }
                <Route path="/user" render={(props) => <User {...props} logProps={logProps} />} />
                <Route path="/passport" render={(props) => <Passport {...props} logProps={logProps} />} />
                <Route component={NotFoundPage} />
            </Switch>
        </div>
    );
  }
}

export default withRouter(Container);
