import React from "react";
import { NavLink } from 'react-router-dom';

//
class Search extends React.Component {
    //
    constructor(props) {
        //
        super(props); 

        const { history, title } = props;

        //
        this.state = {
            // 用来搜索转向的
            history: history,
            // search keyword
            keyword: '',
            // 标
            title: title
        };

        //
        this.keywordChange = this.keywordChange.bind(this);
        this.search = this.search.bind(this);
    }

    //
    componentWillReceiveProps(props){
        let { title } = props;
        this.setState({
            title: title
        });
    }

    //
    keywordChange(event) {
        this.setState({
            keyword: event.target.value
        });
    }

    //
    search() {
       //  const brandId = this.state.brandId;
        // const categoryId = this.state.categoryId;
        const keyword = this.state.keyword;

        // 埋点：点击搜索按钮
        window.sensors.track('search_start', {
            'referrer_page': window.document.title,
            'search_keyword': keyword
        });

        if (!keyword) {
            return;
        }

        const url = `/knowledgebase/search/0/0/${keyword}`;
        const history = this.state.history;
        history.push(url);
    }

    //
    render() {
        const title = this.state.title;

        //
        return (
            <div className="container-fluid bg-white">         
                <div className="container">
                    <div className="search">
                        <div className="title">
                             <NavLink to={'/knowledgebase'}>服务知识库</NavLink> 
                             <span>{title}</span>
                        </div>
                        <div>
                            <input className="value" type="text" value={this.state.keyword} onChange={this.keywordChange} />
                            <button className="bg-blue" onClick={this.search}>搜索</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Search;
