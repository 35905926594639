import React from "react";
// import axios from 'axios';
import { NavLink } from 'react-router-dom';
// module
import Subnav from 'pages/summary/module/subnav.js';
// import { NavLink } from 'react-router-dom';
import Tag from 'pages/summary/module/tag.js';
import Recommend from 'pages/summary/module/recommend.js';
import Search from 'pages/summary/module/search.js';
import Foot from 'pages/summary/module/foot.js';
import Team from 'pages/summary/module/team.js';
// model
import ArticleModel from 'model/article.js';
import UserModel from 'model/user.js';
// component
import BreadcrumbsComponent from 'components/summary/breadcrumbs.js'; 
import BaseView from '../BaseView'

class Article extends BaseView {
    //
    constructor(props) {
        super(props);

        //
        const { history,location,match } = props.props;
        //
        const id = match.params.id;


        this.state = {
            //
            id: id,
            article: {
                tags:[],
                files:[],
                id:0
            },
            //
            history: history,
            location: location,
            // Breadcrumb
            title: '',
            // for breadcrumb
            brandId: 0
        };

        //
        this.collect = this.collect.bind(this);
        this.login = this.login.bind(this);
    }

    //
    // componentWillReceiveProps(props) {
    //     const { match } = props;
    //     //
    //     const id = match.params.id;

    //     //
    //     this.getArticle(id);
    // }

    //
    componentDidMount() {
        const id = this.state.id;
        this.getArticle(id);
    }

    // 
    login() {
        const { setLoginRoute } = this.props;
        setLoginRoute('log-in');
    }

    getArticle(articleId) {
        //
        const that = this;
        /*
        ArticleModel.item(articleId)
        .then(res => {
            let article = res.data;
            article.created_at = article.created_at.substring(0, 10);
            // 看新闻是哪个分类
            that.state.brandId = article.brand_id;
            //
            that.setState({
                article: article,
                title: ' | ' + article.brand + ' | ' + article.category
            });
            //
        });
        */

        //
        ArticleModel.getByTitle(articleId)
        .then(res => {
            let article = res.data;
            article.created_at = article.created_at.substring(0, 10);
            // 看新闻是哪个分类
            that.state.brandId = article.brand_id;
            //
            var title = ' | ' + article.brand + ' | ' + article.category
            that.setState({
                article: article,
                title: title
            });
            this.buryingPoint('天旦Netis | 服务知识库' + title + ' | ' + articleId);
            //
        });
        //
    }

    // collect
    collect() {
        //
        const status = this.state.article.collect === 0 ? 1 :0;
        const articleId = this.state.article.id;
        const that = this;
        UserModel.collect(articleId, status, that.state.article)
        .then(res => {
            that.state.article.collect = status;
            this.setState({
                article: that.state.article
            });
        })
        .catch(res => {
            // console.log(res);
        });
    }

    handleClickAttach(filePath) {
        // 埋点：开始下载知识库附件
        window.sensors.track('downloadKB', {
            'referrer_page': window.document.title,
            'file_name': filePath.substr(filePath.lastIndexOf('/') + 1)
        });
    }

    handleOpenLoginPage() {
        // 埋点：点击注册登录入口
      window.sensors.track('clickEntrance', {
        'referrer_page': window.document.title,
        'source_seat': '由锁定内容触发'
      });
      setTimeout(() => {
        window.location.href = '/passport/login?passport='+window.location.href;
      }, 100);
    }

    //
    render() {
        //
        /*
        if (window.location.href.length > 3)
        window.location.href =  'http://express.124115.com/knowledgebase/article/BPC%E5%A4%9A%E6%AE%B5%E5%85%B3%E8%81%94%E9%85%8D%E7%BD%AE%E6%96%B9%E6%B3%95';
        console.log(window.location.href);
        console.log('11111111');
        */
        //
        const article = this.state.article;
        const history = this.state.history;
        const title = this.state.title;
        const brandId = article.brand_id;

        //
        const tags = this.state.article.tags.map(tag => 
            <NavLink key={tag.id} to={'/knowledgebase/tag/' + tag.id}>{tag.name}</NavLink>
        );

        // console.log(this.state.article);
        const files = this.state.article.files.map(file => 
            <div key={file.id}>
                <NavLink
                    className="btn btn-sm btn-secondary"
                    onClick={() => { this.handleClickAttach(file.path) }}
                    target="_blank"
                    to={'/' + file.path}
                >附件下载</NavLink>
            </div>
        );

        //
        if (UserModel.getVip() !== 'k') {
            return (
                <section>
                    <Search history={history} title={title} />
                    <Subnav history={this.state.history} location={this.state.location} brandId={brandId} />
                    <BreadcrumbsComponent title={title} />
                    <div className="container-fluid">
                        <div className="container">
                            <div className="primary">
                                <div className="content bg-white">
                                    <div className="article">
                                        <div>{article.title}</div>
                                        <div className="parameters">
                                            <span>时间: {article.created_at} </span>
                                            <span>产品类型：{article.bname} </span>
                                            <span>内容类型：{article.cname} 浏览：{article.times}</span>
                                        </div>
                                        <div className="attach">
                                            <span className="collect cursor-pointer" onClick={this.collect.bind(this)}>
                                            {article.collect === 0
                                                ? '收藏'
                                                : '取消收藏'
                                            }
                                            </span>
                                            <span className="tag">标签: {tags}</span>
                                        </div>
                                        <div className="summary">
                                            <div className="title">重点摘要</div>
                                            <div>{article.summary}</div>
                                        </div>

                                        <div className="forbidden">
                                            <div>
                                                <p>当前内容仅限天旦产品用户访问。如需帮助，请联系我们：021-58700166。</p>
                                                <button className="link" onClick={this.handleOpenLoginPage}>立即注册以获得登录权限</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="side">
                                    {(brandId !== undefined) &&
                                        <Tag brandId={brandId} />
                                    }
                                    {(brandId !== undefined) &&
                                        <Recommend brandId={brandId} />
                                    }
                                    <Team />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Foot />
                </section>
            )
        } else {
            //
            return ( 
                <section>
                    <Search history={history} title={title} />
                    <Subnav history={this.state.history} location={this.state.location} brandId={brandId} />
                    <BreadcrumbsComponent title={title} />
                    <div className="container-fluid">
                        <div className="container">
                            <div className="primary">
                                <div className="content bg-white">
                                    <div className="article">
                                        <div>{article.title}</div>
                                        <div className="parameters">
                                            <span>时间: {article.created_at} </span>
                                            <span>产品类型：{article.bname} </span>
                                            <span>内容类型：{article.cname} 浏览：{article.times}</span>
                                        </div>
                                        <div className="attach">
                                            <span className="collect cursor-pointer" onClick={this.collect.bind(this)}>
                                            {article.collect === 0
                                                ? '收藏'
                                                : '取消收藏'
                                            }
                                            </span>
                                            <span className="tag">标签: {tags}</span>
                                        </div>
                                        <div className="text" dangerouslySetInnerHTML = {{__html:article.content}}></div>
                                        <div className="summary">
                                            <div className="title">重点摘要</div>
                                            <div>{article.summary}</div>
                                        </div>
                                        <div>
                                            <div className="download">{files}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="side">
                                    {(brandId !== undefined) &&
                                        <Tag brandId={brandId} />
                                    }
                                    {(brandId !== undefined) &&
                                        <Recommend brandId={brandId} />
                                    }
                                    <Team />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Foot />
                </section>
            )
        }
    }
}

export default Article;
