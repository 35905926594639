import React from "react";
import Cards from "templates/Cards";
import { Query } from 'react-apollo';
import gql from 'graphql-tag';


const GET_POSTS = gql`
  query($first: Int) {
    vodka_posts(first: $first) {
      edges {
        node {
          id 
          title
          date
          slug
          featuredImage {
            sourceUrl
          }
          acf {
            subtitle
            description
          }
        }
      }
    }
  }
`;


class VodkaSpace extends React.Component {
  render() {
    return ( 
      <div>
        <Query query={GET_POSTS} variables={{ first: 100 }}>
          {({ loading, error, data }) => {
            if (loading) return <div className="loader" />;
            if (error) return <p>Error :(</p>;
            if (!data.vodka_posts.edges.length) return <p>No matching posts found.</p>;
            let items = data.vodka_posts.edges;
            return <Cards data={items} logProps={this.props.logProps} />
          }}
        </Query>
      </div>
    )
  }
}

export default VodkaSpace;
