import React from "react";
import { NavLink } from 'react-router-dom';

//
class BreadcrumbsComponent extends React.Component 
{
    //
    constructor(props) {
        //
        super(props); 

        const { title } = props;

        //
        this.state = {
            title: title
        };
    }

    //
    componentWillReceiveProps(props){
        let { title } = props;
        this.setState({
            title: title
        });
    }

    //
    render() {
        const title = this.state.title;

        //
        return ( 
            <div className="container-fluid">
                <div className="container">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <NavLink to={'/knowledgebase'}>服务知识库</NavLink> 
                            </li>
                            <li className="breadcrumb-item active">{title}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        )
    }
}

export default BreadcrumbsComponent;