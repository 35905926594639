// newUser : 新客户
// purchasedUser : 已购用户
// agent : 新代理商
// contractedAgent : 签约代理商
// competitor : 竞争对手
// employee : 天旦员工
// nullUser : 无效用户

// 例不允许新客户 newUser : true

const restrictions = {
  "user-stories": {
    login: true
  },
  "product-info": {
    login: true
  },
  "white-papers": {
    login: true
  },
  "videos": {
    login: {
      body: true,
    },
    bodyRestricted: true
  },
  "market-info": {
    login: true,
    user: {
      level: {
        newUser : true,
        purchasedUser : true,
        agent : true,
        contractedAgent : true,
        competitor : true,
        // employee : 天旦员工,
        nullUser : true,
      },
      message: {
        text: "目前，仅面向NETIS员工开放申请。",
        link: "立即验证用户身份"
      }
    },
    bodyRestricted: true
  },
  "market-resources": {
    login: true,
    user: {
      level: {
        newUser : true,
        purchasedUser : true,
        agent : true,
        contractedAgent : true,
        competitor : true,
        // employee : 天旦员工,
        nullUser : true,
      },
      message: {
        text: "目前，仅面向NETIS员工开放申请。",
        link: "立即验证用户身份"
      }
    },
    bodyRestricted: true
  },
  "magic-club": {
    login: true,
    user: {
      level: {
        newUser : true,
        purchasedUser : true,
        agent : true,
        contractedAgent : true,
        competitor : true,
        // employee : 天旦员工,
        nullUser : true,
      },
      message: {
        text: "目前，仅面向NETIS员工开放申请。",
        link: "立即验证用户身份"
      }
    },
    bodyRestricted: true
  },
  "training": {
    login: true,
    user: {
      level: {
        newUser : true,
        // purchasedUser : 已购用户,
        agent : true,
        // contractedAgent : 签约代理商,
        competitor : true,
        // employee : 天旦员工,
        nullUser : true,
      },
      message: {
        text: "目前，NCCA与NCCD培训仅面向已合作用户开放申请。",
        link: "立即验证用户身份"
      }
    },
    bodyRestricted: true
  },
  "product-docs": {
    login: true,
    user: {
      level: {
        newUser: true,
        agent: true,
        competitor: true,
        nullUser: true
      },
      message: {
        text: "该内容仅面向已合作用户开放申请。",
        link: "立即验证用户身份"
      },
    },
    bodyLinksRestricted: true
  },
  "vodka-space": {
    login: true,
    user: {
      level: {
        newUser: true,
        agent: true,
        competitor: true,
        nullUser: true
      },
      message: {
        text: "该内容仅面向已合作用户开放申请。",
        link: "立即验证用户身份"
      },
    },
    bodyRestricted: true  
  },
  "research-institute": {
    login: {
      body: true,
    },
    bodyRestricted: true
  }
};

export default restrictions;
