import axios from 'axios'
import React from 'react'
import { NavLink } from "react-router-dom"
import { Field, Form } from 'react-final-form'
import Cookies from 'js-cookie'
import thisEnv from 'env'
import { generate, wpToLogInUser } from 'utils/functions'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import SwiperCore, { Autoplay } from 'swiper';
import BaseView from '../BaseView';

SwiperCore.use([Autoplay]);

// 获取URL跳转参数用
function getQueryString(name) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if(pair[0] === name){return pair[1];}
  }
  return(false);
}

class Login extends BaseView {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      watting: false,
      checkedMobile: null,
      code: null,
      awaitingCode: false,
      success:'',
      checkLogged:true
    }
    this.sendCode = this.sendCode.bind(this)
    // this.resetCode = this.resetCode.bind(this)
  }

  componentDidMount() {
    this.buryingPoint('天旦Netis | 登录');
  }

  sendCode() {
    this.setState({ error: null, loading: true })
    const mobileInput = document.querySelector('input[name=mobile]');
    const checkedMobile = mobileInput.value;
    if (checkedMobile === '') {
      this.setState({
        error: 'mobile number null',
        loading: false
      })
      mobileInput.focus();
      return;
    }
    const checkedMobileEncoded = encodeURIComponent(window.btoa(checkedMobile));

    // generate code
    const code = generate(4);
    const codeEncoded = encodeURIComponent(window.btoa(code));

    // send code
    let url = thisEnv.phpUrl;
    url += '/sms/request-encoded.php';

    this.setState({ error: '', });
    window.sensors.track('getCode', {
      // 埋点：发送手机号获取验证码
      'mobile_number': checkedMobile,
      'service_type': '登录'
    });
    
    axios({
      method: 'post',
      url,
      data: {
        aJefLiEAc: codeEncoded,
        DubRKSlhZm: checkedMobileEncoded,
        fDkLzJCi: thisEnv.isMobileLive
      }
    }).then(response => {
      var errorMsg = ''
      if (response.data.Code === 'OK') {
        this.setState({
          error: '',
          loading: false,
          checkedMobile,
          code,
          awaitingCode: true
        })
      } else if (response.data.Code === "isv.BUSINESS_LIMIT_CONTROL") {
        this.setState({
          error: 'mobile limit reached',
          loading: false,
        })
        errorMsg = '已达到移动服务限制'
      } else if (response.data.Code === "isv.MOBILE_NUMBER_ILLEGAL") {
        this.setState({
          error: 'mobile format not allowed',
          loading: false,
        })
        errorMsg = '手机号码格式不正确'
      } else {
        this.setState({
          error: 'mobile code delivery issue',
          loading: false
        })
        errorMsg = '验证码发送失败'
      }

      window.sensors.track('getCodeResult', {
        // 埋点：获取验证码结果
        'service_type': '登录',
        'is_success': errorMsg === '',
        'fail_reason': errorMsg
      });

      // 后段埋点/
      axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
        distinct_id: 'php_'+Date.parse(new Date()),
        event_name: 'getCodeResult',
        datas: {
          'service_type': '登录',
          'is_success': errorMsg === '',
          'fail_reason': errorMsg
        }
      }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
      // /后段埋点

    });
  }
  // resetCode() {
  //   document.querySelector('input[name=code]').value = "";
  //   this.setState({
  //     awaitingCode: false
  //   })
  // }

  doLogin(user) {
    const { logIn, setLoginRoute } = this.props.logProps;
    logIn(user);
    setLoginRoute('login-confirmation');
  }

  render() {
    const { logProps } = this.props;

    // 登录判断
    if(localStorage.getItem('loggedInUser') && !this.state.code){
      // window.history.back(-1);
      // window.location.href="https://www.netis.com/";
      
      // 即使登陆过直接访问此页面就验证的cookie并且登出
      logProps.logOut();
      Cookies.remove('X-Demo-Token');
      axios.get('https://open.netis.com/php/LoginStatus/status.session.clear.php');
      // axios.get('https://open.htmlbox.org/php/LoginStatus/status.session.clear.php');
    }

    // 获取url内的登录后跳转链接
    if(getQueryString('passport')){
      Cookies.set('passport_url',getQueryString('passport'));
    }else{
      Cookies.remove('next_url');
    }

    const onSubmit = (values) => {
      this.setState({ error: null, loading: true, watting: true })
      const mobileInput = document.querySelector('input[name=mobile]');
      const checkedMobile = mobileInput.value;
      const regex = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/; 
      const codeInput = document.querySelector('input[name=code]');
      const checkedCode = codeInput.value;

      window.sensors.track('clickLogin', {
        // 埋点：提交登录
        'referrer_page': window.document.title
      });

      if (checkedMobile === '') {
        this.setState({
          error: 'mobile number null',
          loading: false,
          watting: false
        })
        mobileInput.focus();
        return;
      }else if(!regex.test(checkedMobile)){
        this.setState({
            error: 'mobile format not allowed',
            loading: false,
            watting: false
        })
        return;
      }else if(checkedCode === ''){
        this.setState({
          error: 'mobile code null',
          loading: false,
          watting: false
        })
        codeInput.focus();
        return;
      }else if (values.mobile !== this.state.checkedMobile) {
        this.setState({
          error: 'mobile not match submitted',
          loading: false,
          watting: false
        })

        window.sensors.track('loginResult', {
          // 埋点：登录结果
          'mobile_number': values.mobile,
          'is_success': false,
          'fail_reason': '当前手机号码和表单提交的号码不一致'
        });

        // 后段埋点/
        axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
          distinct_id: 'php_'+Date.parse(new Date()),
          event_name: 'loginResult',
          datas: {
            'mobile_number': values.mobile,
            'is_success': false,
            'fail_reason': '当前手机号码和表单提交的号码不一致'
          }
        }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
        // /后段埋点

      } else {
        let urlCheckMobile = thisEnv.phpUrl + '/wp/checkUser.php';
        urlCheckMobile += '?wpRestAPIUrl=' + thisEnv.wpRestAPIUrl + '&mobile=' + values.mobile;
        // console.log(urlCheckMobile)

        axios.get(urlCheckMobile).then(response => {
          // console.log(response)
          var errorMsg = ''
          if (response.data.length >= 1) {
            if (values.code !== this.state.code) {
              this.setState({
                error: 'code wrong',
                loading: false,
                watting: false
              })
              errorMsg = '验证码不正确'
            } else {
              const logInUser = wpToLogInUser(response.data[0]);
              this.doLogin(logInUser);
              window.sensors.login(response.data[0].id,function(){
              });
              // 登陆成功
              this.setState({
                success: 'success',
                // loading: false,
                // watting: false
              })

              // 登陆成功将登录信息loggedInUser存入session/
              axios.post('https://open.netis.com/php/LoginStatus/status.session.php',{
              // axios.post('https://open.htmlbox.org/php/LoginStatus/status.session.php',{
                loggedInUser: JSON.stringify(logInUser),
              }).then((res)=>{
                console.log(JSON.stringify(res.data))

                // 登录成功后通过passport_url跳转到上次访问页面
                if(Cookies.get('passport_url')){
                  window.location.href = Cookies.get('passport_url');
                }else{
                  window.location.href = '/datacenter';
                }
                Cookies.remove('passport_url');

              }).catch((error)=>{console.log(error)})

            }
          } else {
            this.setState({
              error: 'user not found',
              loading: false,
              watting: false
            })
            errorMsg = '当前手机号码尚未注册'
          }

          window.sensors.track('loginResult', {
            // 埋点：登录结果
            'mobile_number': values.mobile,
            'is_success': errorMsg === '',
            'fail_reason': errorMsg
          });

          // 后段埋点/
          axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
            distinct_id: 'php_'+Date.parse(new Date()),
            event_name: 'loginResult',
            datas: {
              'mobile_number': values.mobile,
              'is_success': errorMsg === '',
              'fail_reason': errorMsg
            }
          }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
          // /后段埋点

        }).catch(error => {
          // console.log(error)
          window.sensors.track('loginResult', {
            // 埋点：登录结果
            'mobile_number': values.mobile,
            'is_success': false,
            'fail_reason': error.message
          });

          // 后段埋点/
          axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
            distinct_id: 'php_'+Date.parse(new Date()),
            event_name: 'loginResult',
            datas: {
              'mobile_number': values.mobile,
              'is_success': false,
              'fail_reason': error.message
            }
          }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
          // /后段埋点

        })
      }
    }

    return (
      <div className="form-holder login" data-success={this.state.success} >
        
          <div className="left">
            <h2>登陆后<br/>您将享有以下权限</h2>
            <ul>
              <li>产品Demo优先体验</li>
              <li>产品优先试用机会</li>
              <li>独家产品资料下载</li>
              <li>天旦用户案例中心</li>
              <li>行业与技术趋势的研究分享</li>
            </ul>
            <div className="macbook">
              <Swiper
                loop={true}
                autoplay={true}
              >
                <SwiperSlide><img src="/static/img/passport_swiper1.jpg" alt="天旦"/></SwiperSlide>
                <SwiperSlide><img src="/static/img/passport_swiper2.jpg" alt="天旦"/></SwiperSlide>
                <SwiperSlide><img src="/static/img/passport_swiper3.jpg" alt="天旦"/></SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="log-in">

            <div className="loading" style={{display:this.state.loading?'block':'none'}}>loading...</div>

            <h4>短信登录<span>LOG IN</span></h4>
            <Form
              onSubmit={onSubmit}
              // initialValues={{ userType: "Trade" }}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}
                noValidate
                >
                  <div className="input-wrap">
                    <Field
                      name="mobile"
                      placeholder="手机号"
                      component="input"
                      type="text"
                      disabled={this.state.awaitingCode}
                      required
                    />
                    <button
                      type="button"
                      onClick={this.sendCode}
                      disabled={this.state.awaitingCode}
                    >获取验证码</button>
                  </div>

                  <Field
                    name="code"
                    placeholder="验证码"
                    component="input"
                    type="text"
                    maxLength="4"
                    disabled={!this.state.awaitingCode}
                    required
                  />

                  <div className="error-holder">
                    {/* <p>checkedMobile: {this.state.checkedMobile}</p> */}
                    {this.state.code &&
                      // <p className="success">验证码: {this.state.code}</p>
                      <p className="success">发送成功</p>
                    }
                    {this.state.error === 'mobile not match submitted' &&
                      <p>当前手机号码和表单提交的号码不一致。</p>
                    }
                    {this.state.error === 'user not found' &&
                      // eslint-disable-next-line
                      <p className="link">当前手机号码尚未注册，请 <NavLink to="/passport/register">注册</NavLink> 。</p>
                    }
                    {this.state.error === 'mobile limit reached' &&
                      <p>已达到移动服务限制。 请在3分钟后再试一次。</p>
                    }
                    {this.state.error === 'mobile format not allowed' &&
                      <p>手机号码格式不正确。 请输入正确的11位数字。</p>
                    }
                    {this.state.error === 'mobile code delivery issue' &&
                      <p>验证码发送失败，稍后请重试。</p>
                    }
                    {this.state.error === 'code wrong' &&
                      <p>输入的验证码不正确，请检查后重新输入。</p>
                    }
                    {this.state.error === 'mobile number null' &&
                        <p>请输入手机号码。</p>
                    }
                    {this.state.error === 'mobile code null' &&
                        <p>请输入验证码。</p>
                    }
                  </div>

                  <div className="submit-wrap">
                    <input
                      type="submit"
                      value={this.state.watting?"登录中...":"登录"}
                      disabled={this.state.loading || !this.state.awaitingCode}
                    />
                    <NavLink to="/passport/register">新用户注册</NavLink>
                  </div>

                  <div className="netis_wechat">
                    <img src="/static/img/passport_netis_wechat.svg" alt="天旦"/>
                    <span>
                      扫描二维码关注天旦Netis公众号，即刻获取天旦最新动态
                    </span>
                  </div>

                </form>
              )}
            />
          </div>
        
      </div>
    )
  }

}

export default Login









