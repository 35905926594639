import React from "react";
import { BrowserRouter, Switch, Route, NavLink } from "react-router-dom";

import LandingPage from 'pages/Netiser/LandingPage';
import MarketInfo from 'pages/Netiser/MarketInfo';
import MarketResources from 'pages/Netiser/MarketResources';
import MagicClub from 'pages/Netiser/MagicClub';

import InnerPage from 'pages/InnerPage';
import NotFoundPage from 'pages/NotFoundPage';

import LoginOverlay from 'forms/DataCenter/LoginOverlay';
import Status from 'components/Status'
import ScrollToTop from 'components/ScrollToTop'


class Index extends React.Component {

  render() {

    let userLevel;
    const getUser = localStorage.getItem('loggedInUser');
    if (getUser) {
      userLevel = JSON.parse(getUser).userLevel;
    }

    let baseUrl = this.props.match.url;
    if (baseUrl.endsWith('/')) {
      baseUrl = baseUrl.slice(0, -1);
    }

    const { logProps } = this.props;

    return (
      <div className="section data">

        <LoginOverlay logProps={logProps} />

        <BrowserRouter>
          <ScrollToTop>
            <div className="navbar">
              <div className="inner">
                <div className="menu">
                  {(userLevel === "employee") && 
                    <>
                      <NavLink to={`${baseUrl}/market-info`}><span>市场情报</span></NavLink>
                      <NavLink to={`${baseUrl}/market-resources`}><span>市场工具包</span></NavLink>
                      <NavLink to={`${baseUrl}/magic-club`}><span>魔术师俱乐部</span></NavLink>
                    </>
                  }
                </div>
                <Status addClass="desktop" logProps={logProps} />
              </div>
            </div>
            <Status addClass="mobile" logProps={logProps} />
            <div className="main">
              <Switch>
                <Route path={`${baseUrl}`} exact={true} render={(props) => <LandingPage logProps={logProps} />} />
                {/* userLevel === "employee" 这里不需要再判断 */}
                  <Route path={`${baseUrl}/market-info`} exact={true} render={(props) => <MarketInfo logProps={logProps} />} />
                  <Route path={`${baseUrl}/market-info/:slug`} exact={true} render={(props) => <InnerPage logProps={logProps} />} />
                  <Route path={`${baseUrl}/market-resources`} exact={true} render={(props) => <MarketResources logProps={logProps} />} />
                  <Route path={`${baseUrl}/market-resources/:slug`} exact={true} render={(props) => <InnerPage logProps={logProps} />} />
                  <Route path={`${baseUrl}/magic-club`} exact={true} render={(props) => <MagicClub logProps={logProps} />} />
                  <Route path={`${baseUrl}/magic-club/:slug`} exact={true} render={(props) => <InnerPage logProps={logProps} />} />
                <Route component={NotFoundPage} />
              </Switch>
            </div>
          </ScrollToTop>
        </BrowserRouter>

      </div>
    )
  }
}


export default Index;
