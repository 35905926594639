import React from "react";
// import { NavLink } from 'react-router-dom';

class Foot extends React.Component {
    //
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    //
    render() {
        return ( 
            <footer className="container-fluid bg-white">
                <div className="container">
                    <div className="foot">
                        沪公网安备 31011502002368号 沪ICP备11017547号-4 网站设计 ©版权所有 2005-2021 上海天旦网络科技发展有限公司（Netis）
                    </div>
                </div>
            </footer>
        )
    }
}

export default Foot;
