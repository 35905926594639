import React from "react";
import { NavLink } from 'react-router-dom';
// import axios from 'axios';
// model
import ArticleModel from 'model/article.js';

//
class Articles extends React.Component {
    //
    constructor(props) {
        //
        super(props);

        //
        this.state = {
            articles: [],
        };
    }

    //
    componentWillReceiveProps(props) {
        this.getArticle();
    }

    //
    componentDidMount() {
        this.getArticle();
        // console.log('ssss');
    }

    //
    getArticle() {
        const that = this;
        const { brandId } = this.props;
        if (brandId === undefined) {
            return;
        } 
        ArticleModel.recommend(brandId)
        .then(function(res) {
            that.setState({
                articles:res.data
            });
        });
    }

    //
    render() {
        //
        const items = this.state.articles.map(article => 
            <div className="recommend" key={article.id}>
                <NavLink to={'/knowledgebase/article/' + article.title}>{article.title}</NavLink>
            </div>
        );

        //
        return ( 
            <div className="recommends bg-white">
                <div className="title">相关知识</div>
                {items}
            </div>
        )
    }
}

export default Articles;
