
// export const getBaseUrl = () => {
//   const url = 'http://netis-data:8888/wp/wp-json/wp/v2/';
//   return url;
// }





export const generate = (n) => {
  var add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.   
  if (n > max) {
    return generate(max) + generate(n - max);
  }
  max = Math.pow(10, n + add);
  var min = max / 10; // Math.pow(10, n) basically
  var number = Math.floor(Math.random() * (max - min + 1)) + min;
  return ("" + number).substring(add);
}

export const formatDate = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  // if (month.length < 2) 
  //     month = '0' + month;
  // if (day.length < 2) 
  //     day = '0' + day;

  return [year, month, day].join('-');
}

export const wpToLogInUser = (wpUser) => {
  const logInUser = {
    name: wpUser.title.rendered,
    company: wpUser.acf.company,
    position: wpUser.acf.position,
    email: wpUser.acf.email,
    mobile: wpUser.acf.mobile,
    userLevel: wpUser.acf.userLevel,
    // increase id
    id: wpUser.id,
    vip: wpUser.vip === 1 ? true : false
  }
  return logInUser;
}












// const shuffle = (array) => {
//   const newArray = array.sort(() => Math.random() - 0.5);
//   return newArray;
// }



// export const dateFormatted = (dateString, locale) => {
//   const jsDate = new Date(dateString);
//   let dateFormatted;
//   let options;
//   if (locale === "en") {
//     options = { month: 'short', day: 'numeric', year: 'numeric' };
//     dateFormatted = jsDate.toLocaleDateString("en-US", options);
//   }
//   if (locale === "zh") {
//     options = { month: 'short', day: 'numeric', year: 'numeric' };
//     dateFormatted = jsDate.toLocaleDateString("zh-CN", options);
//   }
//   return dateFormatted;
// }