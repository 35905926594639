import React from "react";
import SearchModel from 'model/search.js';
import BrandModel from 'model/brand.js';
import CategoryModel from 'model/category.js';
import Foot from 'pages/summary/module/foot.js';
import Team from 'pages/summary/module/team.js';
import Search from 'pages/summary/module/search.js';
//
import Articles from 'components/summary/articles.js';
import BaseView from '../BaseView';

class Filter extends BaseView {
    //
    constructor(props) {
        super(props);

        const { match,history } = props.props;
        const brandId = match.params.brand_id;
        const categoryId = match.params.category_id;
        const keyword = match.params.keyword;
        const options = {
            brand_id: brandId,
            category_id: categoryId,
            keyword: keyword
        };

        this.state = {
            //
            options: options,
            //
            articles: [],
            //
            history: history
        };  
    }

    //
    componentWillReceiveProps(props) {
        //
        const { match } = props.props;
        const brandId = match.params.brand_id;
        const categoryId = match.params.category_id;
        const keyword = match.params.keyword;
        const options = {
            brand_id: brandId,
            category_id: categoryId,
            keyword: keyword
        };
        this.setState({
            options: options
        });
        this.getArticle(options);
    }

    //
    componentWillMount() {
        // console.log('will mount');
    }

    //
    componentDidMount() {
        //
        // console.log('did');
        const options = this.state.options;
        this.getArticle(options);
        this.buryingPoint('天旦Netis | 服务知识库 | 搜索结果')
    }

    //
    componentWillUnmount() {
        // console.log('will unmount');
    }

    getArticle(options) {
        const that = this;
        SearchModel.getArticle(options)
        .then(res => {
            that.setState({
                articles: res.data
            });
            console.log(options, res.data);
            // 埋点：搜索结果显示
            window.sensors.track('search_result', {
                'referrer_page': window.document.title,
                'search_keyword': options.keyword,
                'product': BrandModel.getCleanName(options.brand_id),
                'category': CategoryModel.getCleanName(options.category_id),
                'search_result_yn': res.data.length > 0,
                'search_result_num': res.data.length
            });
        });
    }

    //
    render() {        
        //
        const articles = this.state.articles;
        const keyword = this.state.options.keyword;
        const history = this.state.history;
        //
        const { setLoginRoute } = this.props;

        //
        return ( 
            <section>
                <Search history={history} title={' | ' + keyword} />

                <div className="container-fluid">
                    <div className="container">
                        <div className="primary">
                            <div className="content bg-white">
                                <Articles history={history} articles={articles} setLoginRoute={setLoginRoute} isSearchResult />
                            </div>
                            <div className="side">
                                <Team />
                            </div>
                        </div>
                    </div>
                </div>

                <Foot />
            </section>
        )
    }
}

export default Filter;
