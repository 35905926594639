import axios from 'axios'
import React, { Component } from 'react'
import { Field, Form } from 'react-final-form'
import ReactSVG from 'react-svg'

import thisEnv from 'env'
import { generate, wpToLogInUser } from 'utils/functions'



class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      checkedMobile: null,
      code: null,
      awaitingCode: false
    }
    this.sendCode = this.sendCode.bind(this)
    // this.resetCode = this.resetCode.bind(this)
  }

  sendCode() {
    const mobileInput = document.querySelector('input[name=mobile]');
    const checkedMobile = mobileInput.value;
    if (checkedMobile === '') {
      mobileInput.focus();
      return;
    }
    const checkedMobileEncoded = encodeURIComponent(window.btoa(checkedMobile));

    // generate code
    const code = generate(4);
    const codeEncoded = encodeURIComponent(window.btoa(code));

    // send code
    let url = thisEnv.phpUrl;
    url += '/sms/request-encoded.php';

    this.setState({ error: '', });
    window.sensors.track('getCode', {
      // 埋点：发送手机号获取验证码
      'mobile_number': checkedMobile,
      'service_type': '登录'
    });
    
    axios({
      method: 'post',
      url,
      data: {
        aJefLiEAc: codeEncoded,
        DubRKSlhZm: checkedMobileEncoded,
        fDkLzJCi: thisEnv.isMobileLive
      }
    }).then(response => {
      var errorMsg = ''
      if (response.data.Code === 'OK') {
        this.setState({
          error: '',
          loading: false,
          checkedMobile,
          code,
          awaitingCode: true
        })
      } else if (response.data.Code === "isv.BUSINESS_LIMIT_CONTROL") {
        this.setState({
          error: 'mobile limit reached',
          loading: false,
        })
        errorMsg = '已达到移动服务限制'
      } else if (response.data.Code === "isv.MOBILE_NUMBER_ILLEGAL") {
        this.setState({
          error: 'mobile format not allowed',
          loading: false,
        })
        errorMsg = '手机号码格式不正确'
      } else {
        this.setState({
          error: 'mobile code delivery issue',
          loading: false
        })
        errorMsg = '验证码发送失败'
      }

      window.sensors.track('getCodeResult', {
        // 埋点：获取验证码结果
        'service_type': '登录',
        'is_success': errorMsg === '',
        'fail_reason': errorMsg
      });

      // 后段埋点/
      axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
        distinct_id: 'php_'+Date.parse(new Date()),
        event_name: 'getCodeResult',
        datas: {
          'service_type': '登录',
          'is_success': errorMsg === '',
          'fail_reason': errorMsg
        }
      }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
      // /后段埋点

    });
  }
  // resetCode() {
  //   document.querySelector('input[name=code]').value = "";
  //   this.setState({
  //     awaitingCode: false
  //   })
  // }

  doLogin(user) {
    const { logIn, setLoginRoute } = this.props.logProps;
    logIn(user);
    setLoginRoute('login-confirmation');
  }

  render() {
    const { setLoginRoute } = this.props.logProps;
    const onSubmit = (values) => {
      this.setState({ error: null, loading: true })

      window.sensors.track('clickLogin', {
        // 埋点：提交登录
        'referrer_page': window.document.title
      });

      if (values.mobile !== this.state.checkedMobile) {
        this.setState({
          error: 'mobile not match submitted',
          loading: false
        })

        window.sensors.track('loginResult', {
          // 埋点：登录结果
          'mobile_number': values.mobile,
          'is_success': false,
          'fail_reason': '当前手机号码和表单提交的号码不一致'
        });

        // 后段埋点/
        axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
          distinct_id: 'php_'+Date.parse(new Date()),
          event_name: 'loginResult',
          datas: {
            'mobile_number': values.mobile,
            'is_success': false,
            'fail_reason': '当前手机号码和表单提交的号码不一致'
          }
        }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
        // /后段埋点

      } else {
        let urlCheckMobile = thisEnv.phpUrl + '/wp/checkUser.php';
        urlCheckMobile += '?wpRestAPIUrl=' + thisEnv.wpRestAPIUrl + '&mobile=' + values.mobile;

        axios.get(urlCheckMobile).then(response => {
          var errorMsg = ''
          if (response.data.length >= 1) {
            if (values.code !== this.state.code) {
              this.setState({
                error: 'code wrong',
                loading: false
              })
              errorMsg = '验证码不正确'
            } else {
              const logInUser = wpToLogInUser(response.data[0]);
              this.doLogin(logInUser);
              window.sensors.login(response.data[0].id,function(){
              });

              // 登陆成功将登录信息loggedInUser存入session/
              axios.post('https://open.netis.com/php/LoginStatus/status.session.php',{
                loggedInUser: JSON.stringify(logInUser),
              }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})

            }
          } else {
            this.setState({
              error: 'user not found',
              loading: false
            })
            errorMsg = '当前手机号码尚未注册'
          }

          window.sensors.track('loginResult', {
            // 埋点：登录结果
            'mobile_number': values.mobile,
            'is_success': errorMsg === '',
            'fail_reason': errorMsg
          });

          // 后段埋点/
          axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
            distinct_id: 'php_'+Date.parse(new Date()),
            event_name: 'loginResult',
            datas: {
              'mobile_number': values.mobile,
              'is_success': errorMsg === '',
              'fail_reason': errorMsg
            }
          }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
          // /后段埋点

        }).catch(error => {
          window.sensors.track('loginResult', {
            // 埋点：登录结果
            'mobile_number': values.mobile,
            'is_success': false,
            'fail_reason': error.message
          });

          // 后段埋点/
          axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
            distinct_id: 'php_'+Date.parse(new Date()),
            event_name: 'loginResult',
            datas: {
              'mobile_number': values.mobile,
              'is_success': false,
              'fail_reason': error.message
            }
          }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
          // /后段埋点

        })
      }
    }


    return (
      <div className="form-holder login" >
        <span className="close-x" onClick={() => setLoginRoute(null)}></span>
        <div className="new-account">
          <h4>新用户注册</h4>
          <p>立即注册，获取产品文档、白皮书等资料。</p>
          <div className="submit-wrap">
            <button onClick={() => { setLoginRoute('new-account') }}>立即注册</button>
          </div>
        </div>
        <div className="log-in">
          <h4>登录</h4>

          <Form
            onSubmit={onSubmit}
            // initialValues={{ userType: "Trade" }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}
              noValidate
              >
                <div className="input-wrap">
                  <Field
                    name="mobile"
                    placeholder="手机"
                    component="input"
                    type="text"
                    disabled={this.state.awaitingCode}
                    required
                  />
                  <button
                    type="button"
                    onClick={this.sendCode}
                    disabled={this.state.awaitingCode}
                  >提交</button>
                </div>

                <Field
                  name="code"
                  placeholder="验证码"
                  component="input"
                  type="text"
                  disabled={!this.state.awaitingCode}
                  required
                />

                <div className={'submit-wrap ' + (this.state.loading ? 'loading' : '')}>
                  <span className="loader">
                    <ReactSVG src="/images/ui/loader-form.svg" />
                  </span>
                  {/* <button 
                    type="reset"
                    onClick={this.resetCode}
                  >Reset</button> */}
                  <input
                    type="submit"
                    value="登录"
                    disabled={(this.state.loading || !this.state.awaitingCode)}
                  />
                </div>

              </form>
            )}
          />

          <div className="error-holder">
            {/* <p>checkedMobile: {this.state.checkedMobile}</p> */}
            {this.state.code &&
              // <p>验证码: {this.state.code}</p>
              <p>发送成功</p>
            }
            {this.state.error === 'mobile not match submitted' &&
              <p>错误：当前手机号码和表单提交的号码不一致。</p>
            }
            {this.state.error === 'user not found' &&
              // eslint-disable-next-line
              <p>错误：当前手机号码尚未注册，请<a onClick={() => setLoginRoute('new-account')}>注册</a>。</p>
            }
            {this.state.error === 'mobile limit reached' &&
              <p>错误：已达到移动服务限制。 请在3分钟后再试一次。</p>
            }
            {this.state.error === 'mobile format not allowed' &&
              <p>错误：手机号码格式不正确。 请输入正确的11位数字。</p>
            }
            {this.state.error === 'mobile code delivery issue' &&
              <p>错误：验证码发送失败，稍后请重试。</p>
            }
            {this.state.error === 'code wrong' &&
              <p>错误：输入的验证码不正确，请检查后重新输入。</p>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default LoginForm









