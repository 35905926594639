//
import axios from 'axios';
//
import Env from '../config/env.js';
//
import UserModel from '../model/user.js';

//
class ArticleModel 
{
    static newest(brandId = 0)
    {
        //
        const userId = UserModel.getId();
        const options = {
            'user_id': userId,
            brand_id: brandId
        };
        // const that = this;
        const url = `${Env.base}/article/newest`;
        //
        return axios({
            url: url,
            method: 'get',
            params: options
        });
    }

    //
    static get(brandId = 0, categoryId = 0)
    {
        //
        const userId = UserModel.getId();
        const options = {
            user_id: userId,
            brand_id: brandId,
            category_id: categoryId
        };
        //
        // const that = this;
        const url = `${Env.base}/article`;
        //
        return axios({
            url: url,
            method: 'get',
            params: options
        });
    }

    //
    /*
    static hot()
    {
        //
        const userId = UserModel.getId();
        const options = {
            'user_id': userId
        };
        //
        // const that = this;
        const url = `${Env.base}/hot`;
        //
        return axios({
            url: url,
            method: 'get',
            params: options
        });
    }
    */
    //
    static hot(brandId)
    {
        //
        const userId = UserModel.getId();
        const options = {
            'user_id': userId
        };

        // const that = this;
        const url = `${Env.base}/article/hot?brand_id=${brandId}&limit=3`;
        //
        return axios({
            url: url,
            method: 'get',
            params: options
        });
    }

    //
    static recommend(brandId)
    {
        //
        const userId = UserModel.getId();
        const options = {
            'user_id': userId,
            'brand_id': brandId
        };
        //
        // const that = this;
        const url = `${Env.base}/recommend`;
        //
        return axios({
            url: url,
            method: 'get',
            params: options
        });
    }

    //
    static item(articleId) 
    {
        //
        const userId = UserModel.getId();
        const options = {
            'user_id': userId
        };
        //
        // const that = this;
        const url = `${Env.base}/article/${articleId}`;
        //
        return axios({
            url: url,
            method: 'get',
            params: options
        });
    }

    //
    static getByTitle(title) 
    {
        //
        const userId = UserModel.getId();
        const options = {
            'user_id': userId,
            'title': title
        };
        //
        // const that = this;
        const url = `${Env.base}/article_title`;
        //
        return axios({
            url: url,
            method: 'get',
            params: options
        });
    }

    //
    static getBrandAndStatistics(brandId) 
    {
        //
        const userId = UserModel.getId();
        const options = {
            'user_id': userId
        };
        //
        // const that = this;
        const url = `${Env.base}/brand_and_statistics/${brandId}`;
        //
        return axios({
            url: url,
            method: 'get',
            params: options
        });
    }
}

export default ArticleModel;