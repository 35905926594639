import React, { Component } from 'react'

class Demo extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {

    return (
      <div style={{'background':'#fff','height':'calc(200vh - 54px)'}}>
        Demo
      </div>
    )
  }

}

export default Demo









