import React from "react";
// import { NavLink } from 'react-router-dom';
// import axios from 'axios';
// module
// model
import CollectModel from 'model/collect.js';
import BrandModel from 'model/brand.js';
//
import Foot from 'pages/summary/module/foot.js';
import Team from 'pages/summary/module/team.js';
import Search from 'pages/summary/module/search.js';
import Subnav from 'pages/summary/module/subnav.js';
import Tag from 'pages/summary/module/tag.js';
//
import Articles from 'components/summary/articles.js';
import BreadcrumbsComponent from 'components/summary/breadcrumbs.js';
import BaseView from '../BaseView';

class Collect extends BaseView {
    //
    constructor(props) {
        super(props);

        const { match,history } = props.props;
        const brandId = this.brandNameToId(match.params.brand_id);
       
        //
        this.state = {
            brandId: brandId,
            categoryId: 6,
            //
            history: history,
            //
            articles: []
        };  
    }

    brandNameToId(name) {
        return name === 'bpc' 
            ? 1
            : name === 'npm'
                ? 2
                : 3;
    }

    //
    componentWillReceiveProps(props) {
        //
        /*
        const { match } = props;
        const tagId = match.params.id
        this.getArticle(tagId);
        */
    }

    //
    componentWillMount() {
        // console.log('will mount');
    }

    //
    componentDidMount() {
        const brandId = this.state.brandId;
        this.getArticle(brandId);
        this.buryingPoint(`天旦Netis | 服务知识库 ${BrandModel.getName(brandId)} | 我的收藏`);
    }

    //
    componentWillUnmount() {
        // console.log('will unmount');
    }

    //
    getArticle(brandId) {
        const that = this;
        CollectModel.getArticle(brandId)
        .then(res => {
            const articles = res.data;
            that.setState({
                articles: articles,
            });
        });
    }

    //
    render() {        
        //
        const articles = this.state.articles;
        const history = this.state.history;
        const categoryId = this.state.categoryId;
        const brandId = this.state.brandId;
        const title = BrandModel.getName(brandId) + '| 我的收藏';
        //
        const { setLoginRoute } = this.props;

        //
        return ( 
            <section>
                <Search history={history} title={title} />
                <Subnav  categoryId={categoryId} brandId={brandId} />
                <BreadcrumbsComponent title={title} />
                <div className="container-fluid">
                    <div className="container">
                        <div className="primary">
                            <div className="content bg-white">
                                <Articles history={history} articles={articles} setLoginRoute={setLoginRoute} />
                            </div>
                            <div className="side">
                                <Tag />
                                <Team />
                            </div>
                        </div>
                    </div>
                </div>
                <Foot />
            </section>
        )
    }
}

export default Collect;
