import React from 'react';

import NewAccountForm from 'forms/DataCenter/NewAccountForm'
import LoginForm from 'forms/DataCenter/LoginForm'
import NewAccountConfirmation from 'forms/DataCenter/NewAccountConfirmation'
import LoginConfirmation from 'forms/DataCenter/LoginConfirmation'
import LogOutConfirmation from 'forms/DataCenter/LogOutConfirmation'
import UserAccessRequestForm from 'forms/DataCenter/UserAccessRequestForm'
import UserAccessRequestConfirmation from 'forms/DataCenter/UserAccessRequestConfirmation'
import TrainingApplicationForm from 'forms/DataCenter/TrainingApplicationForm'
import TrainingApplicationConfirmation from 'forms/DataCenter/TrainingApplicationConfirmation'

class LoginOverlay extends React.Component {

  render() {
    const logProps = this.props.logProps;
    const { loginRoute, setLoginRoute } = logProps;

    return (
      loginRoute ?
        <div id="login-overlay">
          <div className="bg" onClick={() => { setLoginRoute(null) }}></div>
          <div className="scroll-wrap">
            <div className="center-wrap">
              <div className="content">

                {(loginRoute === 'log-in') &&
                  <LoginForm logProps={logProps} />
                }

                {(loginRoute === 'log-out') &&
                  <LogOutConfirmation logProps={logProps} />
                }


                {(loginRoute === 'new-account') &&
                  <NewAccountForm logProps={logProps} />
                }

                {(loginRoute === 'login-confirmation') &&
                  <LoginConfirmation logProps={logProps} />
                }

                {(loginRoute === 'new-account-confirmation') &&
                  <NewAccountConfirmation logProps={logProps} />
                }

                {(loginRoute === 'user-access-request-form') &&
                  <UserAccessRequestForm logProps={logProps} />
                }

                {(loginRoute === 'user-access-request-confirmation') &&
                  <UserAccessRequestConfirmation logProps={logProps} />
                }

                {(loginRoute === 'training-application-form') &&
                  <TrainingApplicationForm logProps={logProps} />
                }

                {(loginRoute === 'training-application-confirmation') &&
                  <TrainingApplicationConfirmation logProps={logProps} />
                }                

              </div>
            </div>
          </div>
        </div>
        :
        <div></div>
    );
  }

}

export default LoginOverlay;
