import React from "react";
import { NavLink, withRouter } from 'react-router-dom';
import Breadcrumb from 'components/Breadcrumb';
import { formatDate } from 'utils/functions'


class Cards extends React.Component {

  render() {
    const baseUrl = this.props.match.url;
    const items = this.props.data;

    return (
      <div className="template cards">
        <Breadcrumb />
        <div className="items">
          {items.map(edge => {
            return (
              <Item key={edge.node.id} item={edge.node} baseUrl={baseUrl} logProps={this.props.logProps} />
            );
          })}
        </div>
      </div>
    )
  }
}


const Item = (props) => {
  const { item, baseUrl } = props;

  const thisSection = baseUrl.split('/').pop()
  let showDate = '';
  if (thisSection === 'vodka-space' || thisSection === 'research-institute') {
    showDate = formatDate(item.date);
  }



  return (
    <div className="item">
      {item.featuredImage &&
        <div className="image">
          <img src={item.featuredImage.sourceUrl} alt="" />
        </div>
      }
      <div className="text">
        <div className="contents">
          <div className="headline">
            <NavLink className="link" to={`${baseUrl}/${item.slug}`}>
              <h4>{item.title}
                {item.acf.subtitle &&
                  <span>{item.acf.subtitle}</span>
                }
              </h4>
            </NavLink>
          </div>

          <p className="details">
            {item.acf.description}
            {(item.acf.description && showDate) &&
              <span className="divider"></span>
            }
            <span className="showDate">{showDate}</span>
          </p>
        </div>
        <NavLink className="link more" to={`${baseUrl}/${item.slug}`}>查阅 »</NavLink>
      </div>
    </div>
  )
}


export default withRouter(Cards);
