import React from 'react'

export default class BaseView extends React.Component {
    buryingPoint(title) {
        // 埋点：手动发送页面浏览事件
        window.sensors.registerPage({
            'page_name': title,
            'platform_type': 'web'
        });
        document.title = title;
        window.sensors.quick('autoTrackSinglePage');
    }
}
