import axios from 'axios'
import React, { Component } from 'react'
import { Field, Form } from 'react-final-form'
import ReactSVG from 'react-svg'
import thisEnv from 'env'
import { generate, wpToLogInUser } from 'utils/functions'



class NewAccountFormData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      checkedMobile: null,
      code: null,
      awaitingCode: false
    }
    this.sendCode = this.sendCode.bind(this)
  }

  sendCode() {
    const mobileInput = document.querySelector('input[name=mobile]');
    const checkedMobile = mobileInput.value;
    if (checkedMobile === '') {
      mobileInput.focus();
      return;
    }
    const checkedMobileEncoded = encodeURIComponent(window.btoa(checkedMobile));

    // generate code
    const code = generate(4);
    const codeEncoded = encodeURIComponent(window.btoa(code));

    // send code
    let url = thisEnv.phpUrl;
    url += '/sms/request-encoded.php';

    this.setState({ error: '', });
    // 埋点：发送手机号获取验证码
    window.sensors.track('getCode', {
      'mobile_number': checkedMobile,
      'service_type': '注册'
    });

    axios({
      method: 'post',
      url,
      data: {
        aJefLiEAc: codeEncoded,
        DubRKSlhZm: checkedMobileEncoded,
        fDkLzJCi: thisEnv.isMobileLive
      }
    }).then(response => {
      var errorMsg = ''
      if (response.data.Code === 'OK') {
        this.setState({
          error: '',
          loading: false,
          checkedMobile,
          code,
          awaitingCode: true
        })
      } else if (response.data.Code === "isv.BUSINESS_LIMIT_CONTROL") {
        this.setState({
          error: 'mobile limit reached',
          loading: false,
        })
        errorMsg = '已达到移动服务限制'
      } else if (response.data.Code === "isv.MOBILE_NUMBER_ILLEGAL") {
        this.setState({
          error: 'mobile format not allowed',
          loading: false,
        })
        errorMsg = '手机号码格式不正确'
      }
      else {
        this.setState({
          error: 'mobile code delivery issue',
          loading: false
        })
        errorMsg = '验证码发送失败'
      }

      window.sensors.track('getCodeResult', {
        // 埋点：获取验证码结果
        'service_type': '注册',
        'is_success': errorMsg === '',
        'fail_reason': errorMsg
      });

      // 后段埋点/
      axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
        distinct_id: 'php_'+Date.parse(new Date()),
        event_name: 'getCodeResult',
        datas: {
          'service_type': '登录',
          'is_success': errorMsg === '',
          'fail_reason': errorMsg
        }
      }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
      // /后段埋点

    });
  }

  doLogin(user) {
    const { logIn, setLoginRoute } = this.props.logProps;
    logIn(user);
    setLoginRoute('new-account-confirmation');
  }

  sendEmail(postUser) {

    let url = thisEnv.phpUrl;
    url += '/emails/new-user-datacenter/email.php';

    axios({
      method: 'post',
      url,
      data: {
        postUser,
        isEmailLive: thisEnv.isEmailLive
      }
    }).then(response => {
      // console.log(response.data);
    });

  }


  render() {
    const { setLoginRoute } = this.props.logProps;
    const onSubmit = (values) => {
      this.setState({ error: null, loading: true })

      window.sensors.track('submitReg', {
        // 埋点：提交注册
        'referrer_page': window.document.title,
        'name': values.name,
        'company_name': values.company,
        'position': values.position,
        'email': values.email,
        'mobile_number': values.mobile
      });

      if (values.mobile !== this.state.checkedMobile) {
        this.setState({
          error: 'mobile not match submitted',
          loading: false
        })

        window.sensors.track('registerResult', {
          // 埋点：注册结果
          'is_success': false,
          'fail_reason': '当前手机号码和表单提交的号码不一致'
        });

        // 后段埋点/
        axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
          distinct_id: 'php_'+Date.parse(new Date()),
          event_name: 'registerResult',
          datas: {
            'is_success': false,
            'fail_reason': '当前手机号码和表单提交的号码不一致'
          }
        }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
        // /后段埋点

      } else {
        let urlCheckMobile = thisEnv.phpUrl + '/wp/checkUser.php';
        urlCheckMobile += '?wpRestAPIUrl=' + thisEnv.wpRestAPIUrl + '&mobile=' + values.mobile;

        axios.get(urlCheckMobile).then(response => {
          if (response.data.length >= 1) {
            // email already exists
            this.setState({
              error: 'user exists',
              loading: false
            });

            window.sensors.track('registerResult', {
              // 埋点：注册结果
              'is_success': false,
              'fail_reason': '手机号码已注册'
            });

            // 后段埋点/
            axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
              distinct_id: 'php_'+Date.parse(new Date()),
              event_name: 'registerResult',
              datas: {
                'is_success': false,
                'fail_reason': '手机号码已注册'
              }
            }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
            // /后段埋点

          } else {
            if (values.code !== this.state.code) {
              this.setState({
                error: 'code wrong',
                loading: false
              })

              window.sensors.track('registerResult', {
                // 埋点：注册结果
                'is_success': false,
                'fail_reason': '验证码不正确'
              });

              // 后段埋点/
              axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
                distinct_id: 'php_'+Date.parse(new Date()),
                event_name: 'registerResult',
                datas: {
                  'is_success': false,
                  'fail_reason': '验证码不正确'
                }
              }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
              // /后段埋点

            } else {
              // create new user
              let urlPostUser = thisEnv.phpUrl
              urlPostUser += '/wp/postUser.php'
              const { name, company, position, email, mobile } = values;
              const userLevel = "newUser";
              let postUser = {
                wpRestAPIUrl: thisEnv.wpRestAPIUrl,
                title: name,
                fields: {
                  company,
                  position,
                  email,
                  mobile,
                  userLevel
                },
                status: "publish"
              }
              axios({
                method: 'post',
                url: urlPostUser,
                data: postUser
              }).then(response => {
                var errorMsg = ''
                if (response.data.id) {
                  const logInUser = wpToLogInUser(response.data);
                  this.doLogin(logInUser);
                  window.sensors.login(response.data.id,function(){
                  });
                  var now = new Date();
                  var registerTime = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + ' ' +
                    now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds() + '.' + now.getMilliseconds();
                  window.sensors.setProfile({
                    'name': values.name,
                    'company': values.company,
                    // position?
                    'mobile': values.mobile,
                    'email': values.email,
                    'register_time': registerTime,
                    'userLevel': 'newUser'
                  },function(){
                  });
                  this.sendEmail(values);

                  // 登陆成功将登录信息loggedInUser存入session/
                  axios.post('https://open.netis.com/php/LoginStatus/status.session.php',{
                    loggedInUser: JSON.stringify(logInUser),
                  }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})

                } else {
                  this.setState({
                    error: 'database issue',
                    loading: false
                  });
                  errorMsg = '数据库连接错误'
                }

                window.sensors.track('registerResult', {
                  // 埋点：注册结果
                  'is_success': errorMsg === '',
                  'fail_reason': errorMsg
                });

                // 后段埋点/
                axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
                  distinct_id: 'php_'+Date.parse(new Date()),
                  event_name: 'registerResult',
                  datas: {
                    'is_success': errorMsg === '',
                    'fail_reason': errorMsg
                  }
                }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
                // /后段埋点

              }).catch(error => {
                window.sensors.track('registerResult', {
                  // 埋点：注册结果
                  'is_success': false,
                  'fail_reason': error.message
                });

                // 后段埋点/
                axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
                  distinct_id: 'php_'+Date.parse(new Date()),
                  event_name: 'registerResult',
                  datas: {
                    'is_success': false,
                    'fail_reason': error.message
                  }
                }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
                // /后段埋点

              })
            }
          }
        }).catch(error => {
          window.sensors.track('registerResult', {
            // 埋点：注册结果
            'is_success': false,
            'fail_reason': error.message
          });

          // 后段埋点/
          axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
            distinct_id: 'php_'+Date.parse(new Date()),
            event_name: 'registerResult',
            datas: {
              'is_success': false,
              'fail_reason': error.message
            }
          }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
          // /后段埋点

        })
      }
    }

    return (
      <div className="form-holder new-account">
        <span className="close-x" onClick={() => setLoginRoute(null)}></span>

        <h4>新用户注册</h4>

        <Form
          onSubmit={onSubmit}
          // initialValues={{ userType: "Trade" }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}
            // noValidate
            >
              <Field
                name="name"
                placeholder="姓名*"
                component="input"
                type="text"
                required
              />
              <Field
                name="company"
                placeholder="单位*"
                component="input"
                type="text"
                required
              />
              <Field
                name="position"
                placeholder="职位*"
                component="input"
                type="text"
                required
              />
              <Field
                name="email"
                placeholder="邮箱*"
                component="input"
                type="email"
                required
              />
              <div className="input-wrap">
                <Field
                  name="mobile"
                  placeholder="手机*"
                  component="input"
                  type="text"
                  disabled={this.state.awaitingCode}
                  required
                />
                <button
                  type="button"
                  onClick={this.sendCode}
                  disabled={this.state.awaitingCode}
                >提交</button>
              </div>

              <Field
                name="code"
                placeholder="验证码*"
                component="input"
                type="text"
                disabled={!this.state.awaitingCode}
                required
              />

              {/* <div className="note"><p>请正确填写公司邮箱，这将是您完成下一步验证的重要依据。</p></div> */}

              <div className={'submit-wrap ' + (this.state.loading ? 'loading' : '')}>
                <span className="loader">
                  <ReactSVG src="/images/ui/loader-form.svg" />
                </span>
                <input
                  type="submit"
                  value="注册"
                  disabled={this.state.loading || !this.state.awaitingCode}
                />
              </div>

            </form>
          )}
        />

        <div className="error-holder">
          {/* <p>checkedMobile: {this.state.checkedMobile}</p> */}
          {this.state.code &&
            // <p>验证码: {this.state.code}</p>
            <p>发送成功</p>
          }
          {this.state.error === 'code wrong' &&
            <p>错误：输入的验证码不正确，请检查后重新输入。</p>
          }
          {this.state.error === 'user exists' &&
            // eslint-disable-next-line
            <p>错误：当前手机号码已注册，请<a onClick={() => setLoginRoute('log-in', { 'source_seat': '弹窗内已注册请登录' })}>登陆</a>。</p>
          }
          {this.state.error === 'mobile limit reached' &&
            <p>错误：已达到移动服务限制。 请在3分钟后再试一次。</p>
          }
          {this.state.error === 'mobile format not allowed' &&
            <p>错误：手机号码格式不正确。 请输入正确的11位数字。</p>
          }
          {this.state.error === 'mobile code delivery issue' &&
            <p>错误：验证码发送失败，稍后请重试。</p>
          }
          {this.state.error === 'mobile not match submitted' &&
            <p>错误：当前手机号码和表单提交的号码不一致。</p>
          }
          {this.state.error === 'database connection issue' &&
            <p>错误：数据库连接错误，请稍后再试。</p>
          }
        </div>

      </div>
    )
  }
}

export default NewAccountFormData









