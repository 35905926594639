import React from "react";
// import { NavLink } from 'react-router-dom';
// import axios from 'axios';
// module
// model
import TagModel from 'model/tag.js';
import Foot from 'pages/summary/module/foot.js';
import Team from 'pages/summary/module/team.js';
import Search from 'pages/summary/module/search.js';
//
import Articles from 'components/summary/articles.js';
import BreadcrumbsComponent from 'components/summary/breadcrumbs.js';
import BaseView from '../BaseView';

class Tag extends BaseView {
    //
    constructor(props) {
        super(props);

        //
        const { match,history } = props.props;
        const id = match.params.id

        //
        this.state = {
            id: id,
            articles: [],
            history: history,
            name: ''
        };  
    }

    //
    componentWillReceiveProps(props) {
        //
        const { match } = props.props;
        const tagId = match.params.id
        this.getArticle(tagId);
    }

    //
    componentWillMount() {
        // console.log('will mount');
    }

    //
    componentDidMount() {
        //
        // console.log('did');
        const tagId = this.state.id;
        this.getArticle(tagId);
    }

    //
    componentWillUnmount() {
        // console.log('will unmount');
    }

    getArticle(tagId) {
        const that = this;
        TagModel.getArticle(tagId)
        .then(res => {
            const articles = res.data.articles;
            const tag = res.data.tag;
            that.setState({
                articles: articles,
                name: tag.name
            });
            that.buryingPoint(`天旦Netis | 服务知识库 | 标签 | ${tag.name}`);
        });
    }

    //
    render() {        
        //
        const articles = this.state.articles;
        const history = this.state.history;
        const name = this.state.name;
        //
        const { setLoginRoute } = this.props;
        //
        return ( 
            <section>
                <Search history={history} title={' | ' + name} />
                <BreadcrumbsComponent title={' | ' + name} />
                <div className="container-fluid">
                    <div className="container">
                        <div className="primary">
                            <div className="content bg-white">
                                <Articles history={history} articles={articles} setLoginRoute={setLoginRoute} />
                            </div>
                            <div className="side">
                                
                                <Team />
                            </div>
                        </div>
                    </div>
                </div>

                <Foot />
            </section>
        )
    }
}

export default Tag;
