import React from "react";


class NotFoundPage extends React.Component {
  
  componentDidMount() {
    // pageLoad();
  }
  
  render() {

    return ( 
      <div className="oops">
        <h1>
          <img src="/static/img/404.png" alt="404" />
          <p>你想去的天旦星球太遥远，让旦旦带你点火启航！</p>
          <ul>
            <li><a className="t" href="/passport/login">立即登录/注册</a></li>
            <li><a href="https://www.netis.com">返回首页</a></li>
          </ul>
        </h1>
      </div>
    )
  }
}

export default NotFoundPage;
