import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Breadcrumb from 'components/Breadcrumb';

class Grid extends React.Component {

  render() {
    const baseUrl = this.props.match.url;
    const items = this.props.data;

    return (
      <div className="template grid">
        <Breadcrumb />
        <div className="items">
          {items.map(edge => {
            return (
              <Item key={edge.node.id} item={edge.node} baseUrl={baseUrl} logProps={this.props.logProps} />
            );
          })}
        </div>
      </div>
    )
  }
}


const Item = (props) => {
  const { item, baseUrl } = props;

  return (
    <div className="item">
      <div className="image">
        {item.featuredImage &&
          <img src={item.featuredImage.sourceUrl} alt="" />
        }
      </div>
      <div className="text">
        <div className="contents">
          <div className="headline">
            <NavLink className="link" to={`${baseUrl}/${item.slug}`}>
              <h4>{item.title} <span>{item.acf.subtitle}</span></h4>
            </NavLink>
          </div>
          <p>{item.acf.description}</p>
        </div>
        <NavLink className="link more" to={`${baseUrl}/${item.slug}`}>查阅 »</NavLink>
      </div>
    </div>
  )
}

export default withRouter(Grid);
