import axios from 'axios'
import React from 'react'
import { NavLink } from "react-router-dom";
import { Field, Form } from 'react-final-form'
import Cookies from 'js-cookie'
import thisEnv from 'env'
import { generate, wpToLogInUser } from 'utils/functions'
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import SwiperCore, { Autoplay } from 'swiper';
import BaseView from '../BaseView';

SwiperCore.use([Autoplay]);

const WP_INFO = gql`
  {
    netis_settings(where:{title:"netis_new_account_success_url"}){
      nodes{
        netis_settings{
          value
        }
      }
    }
  }
`;

class Register extends BaseView {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      watting: false,
      checkedMobile: null,
      code: null,
      awaitingCode: false,
    }
    this.sendCode = this.sendCode.bind(this)
  }

  componentDidMount() {
    this.buryingPoint('天旦Netis | 注册');
  }

  // 只发送验证码 不验证手机注册与否
  sendCode() {
    this.setState({ error: null, loading: true })
    const mobileInput = document.querySelector('input[name=mobile]');
    const checkedMobile = mobileInput.value;
    if (checkedMobile === '') {
      this.setState({
        error: 'mobile number null',
        loading: false
      })
      mobileInput.focus();
      return;
    }
    const checkedMobileEncoded = encodeURIComponent(window.btoa(checkedMobile));

    // generate code
    const code = generate(4);
    const codeEncoded = encodeURIComponent(window.btoa(code));

    // send code
    let url = thisEnv.phpUrl;
    url += '/sms/request-encoded.php';

    this.setState({ error: '', });
    // 埋点：发送手机号获取验证码
    window.sensors.track('getCode', {
      'mobile_number': checkedMobile,
      'service_type': '注册'
    });

    axios({
      method: 'post',
      url,
      data: {
        aJefLiEAc: codeEncoded,
        DubRKSlhZm: checkedMobileEncoded,
        fDkLzJCi: thisEnv.isMobileLive
      }
    }).then(response => {
      var errorMsg = ''
      if (response.data.Code === 'OK') {
        this.setState({
          error: '',
          loading: false,
          checkedMobile,
          code,
          awaitingCode: true
        })
      } else if (response.data.Code === "isv.BUSINESS_LIMIT_CONTROL") {
        this.setState({
          error: 'mobile limit reached',
          loading: false,
        })
        errorMsg = '已达到移动服务限制'
      } else if (response.data.Code === "isv.MOBILE_NUMBER_ILLEGAL") {
        this.setState({
          error: 'mobile format not allowed',
          loading: false,
        })
        errorMsg = '手机号码格式不正确'
      }
      else {
        this.setState({
          error: 'mobile code delivery issue',
          loading: false
        })
        errorMsg = '验证码发送失败'
      }

      window.sensors.track('getCodeResult', {
        // 埋点：获取验证码结果
        'service_type': '注册',
        'is_success': errorMsg === '',
        'fail_reason': errorMsg
      });

      // 后段埋点/
      axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
        distinct_id: 'php_'+Date.parse(new Date()),
        event_name: 'getCodeResult',
        datas: {
          'service_type': '登录',
          'is_success': errorMsg === '',
          'fail_reason': errorMsg
        }
      }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
      // /后段埋点

    });
  }

  doLogin(user) {
    const { logIn, setLoginRoute } = this.props.logProps;
    logIn(user);
    setLoginRoute('new-account-confirmation');
  }

  sendEmail(postUser,callBack) {

    let url = thisEnv.phpUrl;
    url += '/emails/new-user-datacenter/email.php';

    axios({
      method: 'post',
      url,
      data: {
        postUser,
        isEmailLive: thisEnv.isEmailLive
      }
    }).then(response => {
      callBack();
      // console.log(response.data);
    });

  }


  render() {
    const { logProps } = this.props;

    // 登录判断
    if(localStorage.getItem('loggedInUser') && !this.state.code){
      // window.history.back(-1);
      // window.location.href="https://www.netis.com/";
      
      // 即使登陆过直接访问此页面就验证的cookie并且登出
      logProps.logOut();
      Cookies.remove('X-Demo-Token');
      axios.get('https://open.netis.com/php/LoginStatus/status.session.clear.php');
    }

    const onSubmit = (values) => {
      this.setState({ error: null, loading: true, watting: true })

      const mobileRegex = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/; 
      const mobileInput = document.querySelector('input[name=mobile]');
      if(mobileInput.value === ''){
        this.setState({ error: 'enter mobile', loading: false, watting: false });
        mobileInput.focus();
        return;
      }else if(!mobileRegex.test(mobileInput.value)){
        this.setState({ error: 'mobile format not allowed', loading: false, watting: false });
        mobileInput.focus();
        return;
      }

      const codeInput = document.querySelector('input[name=code]');
      if(codeInput.value === ''){
        this.setState({ error: 'enter code', loading: false, watting: false });
        codeInput.focus();
        return;
      }

      const nameInput = document.querySelector('input[name=name]');
      if(nameInput.value === ''){
        this.setState({ error: 'enter name', loading: false, watting: false });
        nameInput.focus();
        return;
      }

      const companyInput = document.querySelector('input[name=company]');
      if(companyInput.value === ''){
        this.setState({ error: 'enter company', loading: false, watting: false });
        companyInput.focus();
        return;
      }

      const positionInput = document.querySelector('input[name=position]');
      if(positionInput.value === ''){
        this.setState({ error: 'enter position', loading: false, watting: false });
        positionInput.focus();
        return;
      }

      const emailInput = document.querySelector('input[name=email]');
      const regex = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/; 
      if(emailInput.value === ''){
        this.setState({ error: 'enter email', loading: false, watting: false });
        emailInput.focus();
        return;
      }else if(!regex.test(emailInput.value)){
        this.setState({ error: 'enter email not allowed', loading: false, watting: false });
        return;
      }

      window.sensors.track('submitReg', {
        // 埋点：提交注册
        'referrer_page': window.document.title,
        'name': values.name,
        'company_name': values.company,
        'position': values.position,
        'email': values.email,
        'mobile_number': values.mobile
      });

      if (values.mobile !== this.state.checkedMobile) {
        this.setState({
          error: 'mobile not match submitted',
          loading: false, watting: false
        })

        window.sensors.track('registerResult', {
          // 埋点：注册结果
          'is_success': false,
          'fail_reason': '当前手机号码和表单提交的号码不一致'
        });

        // 后段埋点/
        axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
          distinct_id: 'php_'+Date.parse(new Date()),
          event_name: 'registerResult',
          datas: {
            'is_success': false,
            'fail_reason': '当前手机号码和表单提交的号码不一致'
          }
        }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
        // /后段埋点

      } else {
        let urlCheckMobile = thisEnv.phpUrl + '/wp/checkUser.php';
        urlCheckMobile += '?wpRestAPIUrl=' + thisEnv.wpRestAPIUrl + '&mobile=' + values.mobile;

        axios.get(urlCheckMobile).then(response => {
          if (response.data.length >= 1) {
            // email already exists
            this.setState({
              error: 'user exists',
              loading: false, watting: false
            });

            window.sensors.track('registerResult', {
              // 埋点：注册结果
              'is_success': false,
              'fail_reason': '手机号码已注册'
            });

            // 后段埋点/
            axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
              distinct_id: 'php_'+Date.parse(new Date()),
              event_name: 'registerResult',
              datas: {
                'is_success': false,
                'fail_reason': '手机号码已注册'
              }
            }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
            // /后段埋点

          } else {
            if (values.code !== this.state.code) {
              this.setState({
                error: 'code wrong',
                loading: false, watting: false
              })

              window.sensors.track('registerResult', {
                // 埋点：注册结果
                'is_success': false,
                'fail_reason': '验证码不正确'
              });

              // 后段埋点/
              axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
                distinct_id: 'php_'+Date.parse(new Date()),
                event_name: 'registerResult',
                datas: {
                  'is_success': false,
                  'fail_reason': '验证码不正确'
                }
              }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
              // /后段埋点

            } else {
              // create new user
              let urlPostUser = thisEnv.phpUrl
              urlPostUser += '/wp/postUser.php'
              const { name, company, position, email, mobile } = values;
              const userLevel = "newUser";
              let postUser = {
                wpRestAPIUrl: thisEnv.wpRestAPIUrl,
                title: name,
                fields: {
                  company,
                  position,
                  email,
                  mobile,
                  userLevel
                },
                status: "publish"
              }
              axios({
                method: 'post',
                url: urlPostUser,
                data: postUser
              }).then(response => {
                var errorMsg = ''
                if (response.data.id) {
                  const logInUser = wpToLogInUser(response.data);
                  this.doLogin(logInUser);
                  window.sensors.login(response.data.id,function(){
                  });
                  var now = new Date();
                  var registerTime = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + ' ' +
                    now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds() + '.' + now.getMilliseconds();
                  window.sensors.setProfile({
                    'name': values.name,
                    'company': values.company,
                    // position?
                    'mobile': values.mobile,
                    'email': values.email,
                    'register_time': registerTime,
                    'userLevel': 'newUser'
                  },function(){
                  });

                  // 邮件发送
                  this.sendEmail(values,function(){
                    // 邮件发送成功后
                    // 注册成功将登录信息loggedInUser存入session/
                    axios.post('https://open.netis.com/php/LoginStatus/status.session.php',{
                      loggedInUser: JSON.stringify(logInUser),
                    }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})

                    // 注册成功后通过next_url跳转到上次访问页面
                    if(Cookies.get('next_url')){
                      window.location.href = Cookies.get('next_url');
                    }else{
                      window.location.href = '/datacenter';
                    }
                    Cookies.remove('next_url');
                  });
                  // 注册成功
                  

                  

                } else {
                  this.setState({
                    error: 'database issue',
                    loading: false, watting: false
                  });
                  errorMsg = '数据库连接错误'
                }

                window.sensors.track('registerResult', {
                  // 埋点：注册结果
                  'is_success': errorMsg === '',
                  'fail_reason': errorMsg
                });

                // 后段埋点/
                axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
                  distinct_id: 'php_'+Date.parse(new Date()),
                  event_name: 'registerResult',
                  datas: {
                    'is_success': errorMsg === '',
                    'fail_reason': errorMsg
                  }
                }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
                // /后段埋点

              }).catch(error => {
                window.sensors.track('registerResult', {
                  // 埋点：注册结果
                  'is_success': false,
                  'fail_reason': error.message
                });

                // 后段埋点/
                axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
                  distinct_id: 'php_'+Date.parse(new Date()),
                  event_name: 'registerResult',
                  datas: {
                    'is_success': false,
                    'fail_reason': error.message
                  }
                }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
                // /后段埋点

              })
            }
          }
        }).catch(error => {
          window.sensors.track('registerResult', {
            // 埋点：注册结果
            'is_success': false,
            'fail_reason': error.message
          });

          // 后段埋点/
          axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
            distinct_id: 'php_'+Date.parse(new Date()),
            event_name: 'registerResult',
            datas: {
              'is_success': false,
              'fail_reason': error.message
            }
          }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
          // /后段埋点

        })
      }
    }

    return (
      <div className="form-holder new-account">
        

          <div className="left">
            <h2>注册成功后<br/>您将享有以下权限</h2>
            <ul>
              <li>产品Demo优先体验</li>
              <li>产品优先试用机会</li>
              <li>独家产品资料下载</li>
              <li>天旦用户案例中心</li>
              <li>行业与技术趋势的研究分享</li>
            </ul>
            <div className="macbook">
              <Swiper
                loop={true}
                autoplay={true}
              >
                <SwiperSlide><img src="/static/img/passport_swiper1.jpg" alt="天旦"/></SwiperSlide>
                <SwiperSlide><img src="/static/img/passport_swiper2.jpg" alt="天旦"/></SwiperSlide>
                <SwiperSlide><img src="/static/img/passport_swiper3.jpg" alt="天旦"/></SwiperSlide>
              </Swiper>
            </div>
          </div>

          <div className="reg">
            <div className="loading" style={{display:this.state.loading?'block':'none'}}>loading...</div>

            

            <Form
              onSubmit={onSubmit}
              // initialValues={{ userType: "Trade" }}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}
                noValidate
                >
                  <h4 className="header">
                    新用户注册
                  </h4>
                  <div className="stepBox">
                    <dl>
                      <dd>
                        <Field
                            name="mobile"
                            placeholder="手机号*"
                            component="input"
                            type="text"
                            disabled={this.state.awaitingCode}
                            required
                        />
                      </dd>
                    </dl>
                    <dl className="t">
                      <dd>
                        <Field
                          name="code"
                          placeholder="验证码*"
                          component="input"
                          type="text"
                          maxLength="4"
                          disabled={!this.state.awaitingCode}
                          required
                        />
                        <button
                            type="button"
                            onClick={this.sendCode}
                            disabled={this.state.awaitingCode}
                        >获取验证码</button>
                      </dd>
                    </dl>
                    <dl>
                      <dd>
                        <Field
                          name="name"
                          placeholder="姓名*"
                          component="input"
                          type="text"
                          // required
                      />
                      </dd>
                    </dl>
                    <dl>
                      <dd>
                        <Field
                          name="company"
                          placeholder="单位*"
                          component="input"
                          type="text"
                          // required
                      />
                      </dd>
                    </dl>
                    <dl>
                      <dd>
                        <Field
                          name="position"
                          placeholder="职位*"
                          component="input"
                          type="text"
                          // required
                      />
                      </dd>
                    </dl>
                    <dl className="b">
                      <dd>
                        <Field
                          name="email"
                          placeholder="电子邮箱*"
                          component="input"
                          type="email"
                          // required
                      />
                      </dd>
                    </dl>
                    <dl>
                      <dt></dt>
                      <dd>
                        <div className="error-holder">
                          {this.state.code &&
                            // <p className="success">验证码: {this.state.code}</p>
                            <p className="success">发送成功</p>
                          }
                          {/* 一般情况下不会显示 */}
                          {this.state.error === 'code wrong' &&
                              <p>输入的验证码不正确，请检查后重新输入。</p>
                          }
                          {this.state.error === 'user exists' &&
                              // eslint-disable-next-line
                              <p className="link">当前手机号码已注册，请<NavLink to="/user/log-in">登陆</NavLink>。</p>
                          }
                          {this.state.error === 'mobile limit reached' &&
                              <p>已达到移动服务限制。 请在3分钟后再试一次。</p>
                          }
                          {this.state.error === 'mobile format not allowed' &&
                              <p>手机号码格式不正确。 请输入正确的11位数字。</p>
                          }
                          {this.state.error === 'mobile code delivery issue' &&
                              <p>验证码发送失败，稍后请重试。</p>
                          }
                          {this.state.error === 'mobile not match submitted' &&
                              <p>当前手机号码和表单提交的号码不一致。。。</p>
                          }
                          {this.state.error === 'database connection issue' &&
                              <p>数据库连接错误，请稍后再试。</p>
                          }
                          {this.state.error === 'enter mobile' &&
                              <p>请输入手机号。</p>
                          }
                          {this.state.error === 'enter code' &&
                              <p>请输入验证码。</p>
                          }
                          {this.state.error === 'enter name' &&
                              <p>请输入姓名。</p>
                          }
                          {this.state.error === 'enter company' &&
                              <p>请输入单位。</p>
                          }
                          {this.state.error === 'enter position' &&
                              <p>请输入职位。</p>
                          }
                          {this.state.error === 'enter email' &&
                              <p>请输入电子邮箱。</p>
                          }
                          {this.state.error === 'enter email not allowed' &&
                              <p>请输入正确的电子邮箱。</p>
                          }
                        </div>
                        
                        
                        
                        <input
                          type="submit"
                          value={this.state.watting?"注册中...":"注册"}
                          disabled={this.state.loading || !this.state.awaitingCode}
                          />
                      </dd>
                    </dl>
                    <div className="textR">
                      已有帐户？<NavLink to="/user/log-in">登录</NavLink>
                    </div>
                  </div>

                  
                  


                  

                  

                </form>
              )}
            />
          </div>


        

        {/* 加载注册成功后后台设置的 netis_new_account_success_url 跳转页面 */}
        <Query query={WP_INFO}>
          {({ loading, error, data }) => {
            if (loading) return '';// loading
            if (error) return <p>Error!</p>;
            // console.log(data.netis_settings.nodes[0].netis_settings.value);
            return '';
          }}
        </Query>
      </div>
    )
  }
}

export default Register









