import React from "react";
import Inner from "templates/Inner"

class InnerPage extends React.Component {
  
  componentDidMount() {
    // pageLoad();
  }
  
  render() {

    return ( 
      <Inner logProps={this.props.logProps} />
    )
  }
}

export default InnerPage;
