import React from 'react';
import './styles/styles.scss';
import AppRouter from './routers/AppRouter';
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import thisEnv from './env'
import { HelmetProvider } from 'react-helmet-async';




const client = new ApolloClient({
  uri: thisEnv.wpGraphQLUrl
});


function App() {
  return (
    <HelmetProvider>
      <ApolloProvider client={client}>
        <AppRouter></AppRouter>
      </ApolloProvider>
    </HelmetProvider>
  );
}


export default App;


export const siteTitle = "天旦Netis";
