import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


class Breadcrumb extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      b1: {},
      b2: {},
      b3: {}
    };
  }

  componentDidMount() {
    this.setValues();
  }

  setValues() {
    const m1 = document.querySelector('nav a.active');
    const m2 = document.querySelector('.navbar a.active');
    const newState = {}
    if (m1) {
      newState.b1 = {
        text: m1.innerText,
        link: m1.pathname.split('/').pop()
      }
    }
    
    if (m2) {
      newState.b2 = {
        text: m2.innerText,
        link: m2.pathname.split('/').pop()
      }
    }

    if (m1 || m2) {
      this.setState(newState, () => {
        let title = this.autoTitle();
        // 埋点：手动发送页面浏览事件
        window.sensors.registerPage({
          'page_name': title,
          'platform_type': 'web'
        });
        window.document.title = title;
        window.sensors.quick('autoTrackSinglePage');
      })
    }
  }

  autoTitle() {
    let autoTitle = "天旦Netis";
    if (this.state.b1.text) {
      autoTitle += ' | ' + this.state.b1.text;
    }
    if (this.state.b2.text) autoTitle += ' > ' + this.state.b2.text;
    if (this.props.b3Text) autoTitle += ' > ' + this.props.b3Text;
    return autoTitle;
  }

  render() {
    let seo = {
      title: this.autoTitle(),
      metaDescription: null,
      metaKeywords: null
    }
    if (this.props.seo) {  // overwrite if set
      if (this.props.seo.title)  seo.title = (this.props.seo.title);
      if (this.props.seo.metaDescription)  seo.metaDescription = (this.props.seo.metaDescription);
      if (this.props.seo.metaKeywords)  seo.metaKeywords = (this.props.seo.metaKeywords);
    }

    if (this.props.b3Text) {
      return (
        <div className="breadcrumb">
          <ThisHelmet seo={seo} />
          <NavLink to={`/${this.state.b1.link}`}>{this.state.b1.text}</NavLink>
          <Span />
          <NavLink to={`/${this.state.b1.link}/${this.state.b2.link}`}>{this.state.b2.text}</NavLink>
          <Span />
          {this.props.b3Text}
        </div>
      );
    }
    else if (this.state.b2 && this.state.b2.text) {
      return (
        <div className="breadcrumb">
          <ThisHelmet seo={seo} />
          <NavLink to={`/${this.state.b1.link}`}>{this.state.b1.text}</NavLink>
          <Span />
          {this.state.b2.text}
        </div>
      );
    }
    else if (this.state.b1) {
      return (
        <div className="breadcrumb">
          <ThisHelmet seo={seo} />
          {this.state.b1.text}
        </div>
      )
    }
  }

}

const ThisHelmet = (props) => {
  const { title, metaDescription, metaKeywords } = props.seo;
  return (
    <Helmet>
      <title>{title}</title>
      {metaDescription &&
        <meta name="description" content={metaDescription} />
      }
      {metaKeywords &&
        <meta name="keywords" content={metaKeywords} />
      }
    </Helmet>
  )
}


const Span = () => {
  return <span>›</span>
}

export default withRouter(Breadcrumb);
