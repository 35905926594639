import React, { Component } from 'react'


class NewAccountConfirmation extends Component {

  render() {
    const logProps = this.props.logProps;
    const { setLoginRoute } = logProps;
    return (
      <div className="form-holder confirmation">
        <span className="close-x" onClick={ () => setLoginRoute(null) }></span>
        <h4>成功</h4>
        <p>您已完成注册。</p>
        <p>登录成功。</p>
        <div className="submit-wrap">
          <button onClick={ () => { setLoginRoute(null) }}>关闭</button>
        </div>
      </div>
    )
  }
}

export default NewAccountConfirmation









