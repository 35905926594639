import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Breadcrumb from 'components/Breadcrumb';


class Landing extends React.Component {

  componentDidMount() {
    // pageLoad();
  }

  render() {

    let baseUrl = this.props.match.url;
    if (baseUrl.endsWith('/')) {
      baseUrl = baseUrl.slice(0, -1);
    }
    let thisSection = baseUrl.substr(1);

    let items = {
      datacenter: [
        {
          title: "案例中心",
          description: "天旦用户案例中心，获取天旦用户的成功实践经验",
          link: "user-stories"
        },
        {
          title: "资料下载",
          description: "丰富的天旦产品资料，一站式获取",
          link: "product-info"
        },
        {
          title: "白皮书",
          description: "洞悉行业与技术趋势的研究分享",
          link: "white-papers"
        },
        {
          title: "产品视频",
          description: "产品理念视频、操作视频、演示视频",
          link: "videos"
        },
      ],
      college: [
        {
          title: "使用培训",
          description: "为天旦使用者专设的产品培训课程，旨在帮助用户快速上手、熟悉使用、持续创新。",
          link: "training"
        },
        /*
        {
          title: "用户手册",
          description: "天旦产品用户的速查使用手册，帮助使用者答疑解惑产品使用中的各种问题。",
          link: "product-docs"
        },
        */
        /*
        {
          title: "伏特加空间", 
          description: "Netis Support团队技术分享，为你解答运维工作中的各种技术难题。",
          link: "vodka-space"
        },
        */
        {
          title: "回看研讨会", 
          description: "洞悉运维风向，来自天旦与行业专家的独家理念，为你拨开迷雾，探索智能运维的未来。",
          link: "research-institute"
        },
      ],
      netiser: [
        {
          title: "市场情报",
          description: "迅速了解行业资讯、政策变化、客户动态等重要信息",
          link: "market-info"
        },
        {
          title: "市场工具包",
          description: "市场资料下载中心",
          link: "market-resources"
        },
        {
          title: "魔术师俱乐部",
          description: "面向员工的趣味活动、问卷调研等互动中心",
          link: "magic-club"
        },
      ]
    }
    const employeeItems = { 
      datacenter: [
        {
          title: "市场情报",
          description: "迅速了解行业资讯、政策变化、客户动态等重要信息",
          link: "market-info"
        },
        {
          title: "市场工具包",
          description: "市场资料下载中心",
          link: "market-resources"
        },
        {
          title: "魔术师俱乐部",
          description: "面向员工的趣味活动、问卷调研等互动中心",
          link: "magic-club"
        },
      ]
    }
    const getUser = localStorage.getItem('loggedInUser');
    if (getUser) {
      const userLevel = JSON.parse(getUser).userLevel;
      if (userLevel === "employee___remove_oy") {
        items.datacenter = items.datacenter.concat(employeeItems.datacenter)
      }
    }

    return (
      <div className="template landing">
        <Breadcrumb />
        <div className="items">
          {items[thisSection].map((item, index) => <Item key={index} data={item} baseUrl={baseUrl} thisSection={thisSection} logProps={this.props.logProps} />)}
        </div>
      </div>
    )
  }
}

const Item = (props) => {
  const { title, description, link } = props.data;
  const { baseUrl } = props;
  return (
    <div className="item">
      <div className="text">
        <div className="contents">
          <NavLink className="link" to={`${baseUrl}/${link}`}>
            <h4>{title}</h4>
          </NavLink>
          <p>{description}</p>
        </div>
        <NavLink className="link more" to={`${baseUrl}/${link}`}>查阅 »</NavLink>
      </div>
    </div>
  )
}

export default withRouter(Landing);
