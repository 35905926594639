import React from "react";
import { NavLink } from 'react-router-dom';
// import axios from 'axios';
// model
// import TagModel from 'model/tag.js';
import UserModel from 'model/user.js';
// components
import TagComponent from 'components/summary/tags.js';

//
class Articles extends React.Component {
    //
    constructor(props) {
        super(props);
        const { articles } = props;

        this.state = {
            articles: articles
        };

        this.collect = this.collect.bind(this);
        //
        this.turnTo = this.turnTo.bind(this);
    }

    componentWillReceiveProps(props) {
        //
        const { articles } = props;
        this.setState({
          articles: articles
        });
    }

    //
    componentWillMount() {
        console.log('will mount');
    }

    //
    componentDidMount() {
        //
        // console.log('did-----');
        // console.log(this.props);
    }

    //
    componentWillUnmount() {
        // console.log('will unmount');
    }

    // collect
    collect(key, article) {
        //
        const status = this.state.articles[key].collect === 0 ? 1 :0;
        const articleId = this.state.articles[key].id;
        // const { setLoginRoute } = this.props;
        
        //
        if (UserModel.getId() <= 0) {
            // setLoginRoute('log-in');
            window.location.href = '/passport/login?passport='+window.location.href;
            return;
        } 

        //
        const that = this;
        UserModel.collect(articleId, status, article)
        .then(res => {
            that.state.articles[key].collect = status;
            this.setState({
                articles: that.state.articles
            });
        })
        .catch(res => {
            // console.log(res);
        });
    }

    //
    link(article, index) {
        // if (article.vip === 1) {
        if (false) {
            return <NavLink className="cursor-pointer" to={'/knowledgebase/article/' + article.title}>{article.title}</NavLink>
        } else { 
            return <div className="pretend cursor-pointer" onClick={this.turnTo.bind(this,article,index)}>{article.title}</div>
        }
    }

    //
    read(article, index) {
        // if (article.vip === 1) {
        if (false) {
            return <NavLink className="cursor-pointer" to={'/knowledgebase/article/' + article.title}>阅读全文</NavLink>
        } else { 
            return <div className="pretend cursor-pointer" onClick={this.turnTo.bind(this,article,index)}>阅读全文 &gt;</div>
        }
    }

    turnTo(article, index) 
    {
        //
        // const { setLoginRoute } = this.props;

        if (this.props.isSearchResult) {
            let tagStr = '';
            (article.tags || []).forEach(tag => {
                if (tagStr) {
                    tagStr += ',';
                }
                tagStr += tag.name;
            })
            // 埋点：点击搜索结果
            window.sensors.track('search_result_click', {
                'referrer_page': window.document.title,
                'article_title': article.title,
                'product': article.bname,
                'category': article.cname,
                'article_tag': tagStr,
                'article_sorting': `${index + 1}`
            });
        }

        //
        if (UserModel.getId() <= 0) {
            // setLoginRoute('log-in');
            window.location.href = '/passport/login?passport='+window.location.href;
            return;
        } 
        const url = `/knowledgebase/article/${article.title}`;
        this.props.history.push(url);
    }

    //
    render() {
        //
        const articles = this.state.articles;
        console.log(articles);

        //
        let items;
        if (articles.length <= 0) {
             items = <div>没有找到相关的文章。</div>;
        } else {
            items = articles.map((article, key) => 
                <div className="article" key={article.id}>
                     <div className="titile">
                        { this.link(article, key) }
                    </div>
                     <div className="parameters">
                          <span>时间：{article.created_at.substring(0, 10)}</span>
                          <span>产品类型：{article.bname}</span>
                          <span>内容类型：{article.cname}</span>
                          <span>浏览：{article.times}</span>
                    </div>


                    <div className="text">
                        <span dangerouslySetInnerHTML={{__html:article.intro}}></span>
                        { this.read(article, key) }
                    </div>
                    <div className="attach">
                        <span className="collect cursor-pointer" onClick={this.collect.bind(this, key, article)}>
                        {article.collect === 0 ? '收藏' : '取消收藏'}
                        </span>
                        <TagComponent tags={article.tags} />
                     </div>
                </div>
            );
        };


        return ( 
            <div className="articles">{items}</div>
        );
    }
}

export default Articles;
