import React, { useEffect } from "react";
import Icon from 'components/Icon';
import { Link, withRouter } from 'react-router-dom';
import Breadcrumb from 'components/Breadcrumb';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import NotFoundPage from 'pages/NotFoundPage';
import ReactSVG from 'react-svg'

import download from 'downloadjs'
import restrictions from 'settings/restrictions';
const axios = require('axios');



const gqlQueries = {
  GET_USER_STORY: gql`
    query ($slug: String!) {
      user_storyBy(slug: $slug) {
        title
        featuredImage {
          sourceUrl
        }
        acf {
          subtitle
          description
          bodyText
          fileUrl
          loggedRead
          innerImage {
            sourceUrl
          }
        }
        acf_seo {
          title
          metaDescription
          metaKeywords
        }
      }  
    }
  `,
  GET_PRODUCT_INFO: gql`
    query ($slug: String!) {
      product_infoBy(slug: $slug) {
        title
        featuredImage {
          sourceUrl
        }
        acf {
          subtitle
          description
          bodyText
          fileUrl
          loggedRead
          innerImage {
            sourceUrl
          }
        }
        acf_seo {
          title
          metaDescription
          metaKeywords
        }
      }  
    }
  `,
  GET_WHITE_PAPER: gql`
    query ($slug: String!) {
      white_paperBy(slug: $slug) {
        title
        featuredImage {
          sourceUrl
        }
        acf {
          subtitle
          description
          bodyText
          fileUrl
          loggedRead
          innerImage {
            sourceUrl
          }
        }
        acf_seo {
          title
          metaDescription
          metaKeywords
        }
      }  
    }
  `,
  GET_VIDEO: gql`
    query ($slug: String!) {
      videoBy(slug: $slug) {
        title
        featuredImage {
          sourceUrl
        }
        acf {
          subtitle
          description
          bodyText
          fileUrl
          loggedRead
          innerImage {
            sourceUrl
          }
        }
        acf_v {
          videoUrl
        }
        acf_seo {
          title
          metaDescription
          metaKeywords
        }
      }  
    }
  `,
  GET_MARKET_INFO: gql`
    query ($slug: String!) {
      market_infoBy(slug: $slug) {
        title
        featuredImage {
          sourceUrl
        }
        acf {
          subtitle
          description
          bodyText
          fileUrl
          loggedRead
          innerImage {
            sourceUrl
          }
        }
        acf_seo {
          title
          metaDescription
          metaKeywords
        }
      }  
    }
  `,
  GET_MARKET_RESOURCE: gql`
    query ($slug: String!) {
      market_resourceBy(slug: $slug) {
        title
        featuredImage {
          sourceUrl
        }
        acf {
          subtitle
          description
          bodyText
          fileUrl
          loggedRead
          innerImage {
            sourceUrl
          }
        }
        acf_seo {
          title
          metaDescription
          metaKeywords
        }
      }  
    }
  `,
  GET_MAGIC_CLUB: gql`
    query ($slug: String!) {
      magic_clubBy(slug: $slug) {
        title
        featuredImage {
          sourceUrl
        }
        acf {
          subtitle
          description
          bodyText
          fileUrl
          loggedRead
          innerImage {
            sourceUrl
          }
        }
        acf_seo {
          title
          metaDescription
          metaKeywords
        }
      }  
    }
  `,
  GET_TRAINING: gql`
    query ($slug: String!) {
      trainingBy(slug: $slug) {
        title
        featuredImage {
          sourceUrl
        }
        acf {
          subtitle
          description
          bodyText
          fileUrl
          loggedRead
          innerImage {
            sourceUrl
          }
        }
        acf_seo {
          title
          metaDescription
          metaKeywords
        }
      }  
    }
  `,
  GET_PRODUCT_DOC: gql`
    query ($slug: String!) {
      product_docBy(slug: $slug) {
        title
        featuredImage {
          sourceUrl
        }
        acf {
          subtitle
          description
          bodyText
          fileUrl
          loggedRead
          innerImage {
            sourceUrl
          }
        }
        acf_pd {
          documentationSlug
        }
        acf_seo {
          title
          metaDescription
          metaKeywords
        }
      }  
    }
  `,
  GET_VODKA_POST: gql`
    query ($slug: String!) {
      vodka_postBy(slug: $slug) {
        id
        title
        slug
        acf {
          subtitle
          description
          bodyText
          fileUrl
          loggedRead
        }
        acf_seo {
          title
          metaDescription
          metaKeywords
        }
      }  
    }
  `,
  GET_ALL_VODKA_POSTS: gql`
    {
      vodka_posts {
        edges {
          node {
            id
            title
            date
            slug
          }
        }
      }
    }
  `,
  GET_RESEARCH_POST: gql`
    query ($slug: String!) {
      research_postBy(slug: $slug) {
        id
        title
        slug
        acf {
          subtitle
          description
          bodyText
          fileUrl
          loggedRead
        }
        acf_seo {
          title
          metaDescription
          metaKeywords
        }
      }  
    }
  `,
  GET_ALL_RESEARCH_POSTS: gql`
    {
      research_posts {
        edges {
          node {
            id
            title
            date
            slug
          }
        }
      }
    }
  `,  
}




class Inner extends React.Component {

  render() {

    const baseSlug = this.props.match.params.slug;
    const variables = { slug: baseSlug }

    const url = this.props.match.url;
    let urlArray = url.split('/');
    urlArray.shift();  // remove blank
    const thisSection = urlArray[1];

    const sections = {
      "user-stories": {
        query: gqlQueries.GET_USER_STORY,
        data: "user_storyBy"
      },
      "product-info": {
        query: gqlQueries.GET_PRODUCT_INFO,
        data: "product_infoBy"
      },
      "white-papers": {
        query: gqlQueries.GET_WHITE_PAPER,
        data: "white_paperBy"
      },
      "videos": {
        query: gqlQueries.GET_VIDEO,
        data: "videoBy"
      },
      "market-info": {
        query: gqlQueries.GET_MARKET_INFO,
        data: "market_infoBy"
      },
      "market-resources": {
        query: gqlQueries.GET_MARKET_RESOURCE,
        data: "market_resourceBy"
      },
      "magic-club": {
        query: gqlQueries.GET_MAGIC_CLUB,
        data: "magic_clubBy"
      },
      "training": {
        query: gqlQueries.GET_TRAINING,
        data: "trainingBy"
      },
      "product-docs": {
        query: gqlQueries.GET_PRODUCT_DOC,
        data: "product_docBy"
      },
      "vodka-space": {
        query: gqlQueries.GET_VODKA_POST,
        data: "vodka_postBy"
      },
      "research-institute": {
        query: gqlQueries.GET_RESEARCH_POST,
        data: "research_postBy"
      },
    }
    return (
      <Query query={sections[thisSection].query} variables={variables} >
        {({ loading, error, data }) => {
          if (loading) return <div className="loader" />;
          if (error) return <NotFoundPage />

          const item = data[sections[thisSection].data];
          return <Content item={item} baseUrl={urlArray[0]} thisSection={thisSection} logProps={this.props.logProps} />
        }}
      </Query>
    )
  }
}

const BodyText = (props) => {
  const { bodyText } = props

  // 取video的参数
  function getVideoParams(video) {
    const videoUrl = video.querySelector('a').text
    const nameWithSuffix = videoUrl.substring(videoUrl.lastIndexOf('/') + 1)
    let nameWithoutSuffix = nameWithSuffix.substr(0, nameWithSuffix.indexOf('.'))
    let videoType = ''
    if (nameWithoutSuffix.indexOf('_') !== -1) {
      videoType = nameWithoutSuffix.substr(0, nameWithoutSuffix.indexOf('_'))
      nameWithoutSuffix = nameWithoutSuffix.substring(nameWithoutSuffix.indexOf('_') + 1)
    }
    return {
      'video_type': videoType + '视频',
      'video_id': nameWithoutSuffix
    }
  }

  useEffect(() => {
    let videoStatusMap = {}
    const videos = document.querySelectorAll('video');

    function handlePlay() {
      videoStatusMap[this.id].startPlayTime = new Date().valueOf();
    }

    function handlePause() {
      if (videoStatusMap[this.id].startPlayTime) {
        videoStatusMap[this.id].totalPlayTime += new Date().valueOf() - videoStatusMap[this.id].startPlayTime;
      }
      videoStatusMap[this.id].startPlayTime = null;
    }

    function handleEnded() {
      window.sensors.track('start_video_play', {
        // 埋点：记录播放时长
        'is_finish': true,
        'referrer_page': window.document.title,
        '$event_duration': Math.floor(videoStatusMap[this.id].totalPlayTime / 1000),
        ...getVideoParams(this)
      });
      videoStatusMap[this.id].startPlayTime = null;
      videoStatusMap[this.id].totalPlayTime = 0;
    }

    // 跳转至其他页面、或者刷新页面、关闭页面时，发送播放信息到神策
    function sendTrack() {
      // eslint-disable-next-line no-unused-vars
      for (let id in videoStatusMap) {
        const videoElem = document.getElementById(id);
        if (videoElem) {
          let videoStatus = videoStatusMap[id];
          if (videoStatus.startPlayTime || videoStatus.totalPlayTime) {
            let totalPlayTime = videoStatus.totalPlayTime;
            if (videoStatus.startPlayTime) {
              totalPlayTime += new Date().valueOf() - videoStatus.startPlayTime;
            }
            window.sensors.track('start_video_play', {
              // 埋点：记录播放时长
              'is_finish': false,
              'referrer_page': window.document.title,
              '$event_duration': Math.floor(totalPlayTime / 1000),
              ...getVideoParams(videoElem)
            });
          }
        }
      }
    }
    
    for (let i = 0; i < videos.length; i++) {
      videoStatusMap[videos[i].id] = {
        startPlayTime: null,
        totalPlayTime: 0
      }
      videos[i].addEventListener('play', handlePlay);
      videos[i].addEventListener('pause', handlePause);
      videos[i].addEventListener('ended', handleEnded);
    }

    window.onbeforeunload = function(event) {
      sendTrack();
    }

    return () => {
      sendTrack();
      for (let i = 0; i < videos.length; i++) {
        videos[i].removeEventListener('play', handlePlay);
        videos[i].removeEventListener('pause', handlePause);
        videos[i].removeEventListener('ended', handleEnded);
      }
    }
  }, [bodyText]);

  return (
    <div className="body">
      <div dangerouslySetInnerHTML={{ __html: bodyText }} />
    </div>
  )
}

class Content extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      userAccess: null,
      userMessage: {
        text: "",
        link: ""
      },
    }
    this.downloadFromUrl = this.downloadFromUrl.bind(this)
  }
  
  componentDidMount() {
    this.setUserAccess();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.userAccess !== prevState.userAccess) {
      this.setUserAccess();
    }
  }
  componentWillReceiveProps() {
    setTimeout(() => this.setUserAccess(), 1)
  }
  setUserAccess() {
    const { thisSection } = this.props;
    const { loggedInUser } = this.props.logProps;
    const thisRestrictions = restrictions[thisSection];
    let setUserMessage = {
      text: "(text missing)",
      link: "(link missing)"
    };
    if (thisRestrictions.user) {
      if (thisRestrictions.user.message)  setUserMessage = thisRestrictions.user.message;
    }
    this.setState({ userMessage: setUserMessage })

    if (thisRestrictions.login && !loggedInUser) {
      this.setState({ userAccess: "loginRestricted" });
    } else if (thisRestrictions.user && thisRestrictions.user.level[loggedInUser.userLevel]) {
      this.setState({ userAccess: "userRestricted" })
    } else {
      this.setState({ userAccess: "ok" })
    }

    let bodyAccess = true;
    if (thisRestrictions.bodyRestricted) {
      // if user is logged in, and there are restrictions for user, and user is not ok
      if (loggedInUser && thisRestrictions.user && thisRestrictions.user.level[loggedInUser.userLevel] && this.state.userAccess !== "ok") {
        bodyAccess = false;
      // else if user is not logged in, and there are restrictions for login
      } else if (!loggedInUser && thisRestrictions.login) {
        bodyAccess = false
      }
    }
    this.setState({ bodyAccess })

    let bodyLinksAccess = true;
    if (thisRestrictions.bodyLinksRestricted) {
      // if user is logged in, and there are restrictions for user, and user is not ok
      if (loggedInUser && thisRestrictions.user && thisRestrictions.user.level[loggedInUser.userLevel] && this.state.userAccess !== "ok") {
        bodyLinksAccess = false;
      // else if user is not logged in, and there are restrictions for login
      } else if (!loggedInUser && thisRestrictions.login) {
        bodyLinksAccess = false
      }
    }
    this.setState({ bodyLinksAccess })
  }

  downloadFromUrl(url) {
    const { item } = this.props;

    // 埋点：开始下载文档
    window.sensors.track('downloadPDF', {
      'file_name': (item.acf && item.acf.subtitle) ? `${item.title}-${item.acf.subtitle}` : item.title
    });

    this.setState({ loading: true });
    axios.get((url), {
      responseType: 'blob', // had to add this one here
    }).then(response => {
      const filename = url.split('/').pop();
      const content = response.headers['content-type'];
      download(response.data, filename, content)
      this.setState({ loading: false });

      // 埋点：下载成功
      window.sensors.track('downloadSuccess', {
        'is_success': true,
        'fail_reason': ''
      });
    })
      .catch(error => {
        // console.log(error);
        // 埋点：下载失败
        window.sensors.track('downloadSuccess', {
          'is_success': false,
          'fail_reason': (error || {}).message
        });
      });
  }

  handleOpenLoginPage(sourceSeat) {
    // 埋点：点击注册登录入口
    window.sensors.track('clickEntrance', {
      'referrer_page': window.document.title,
      'source_seat': sourceSeat
    });
    setTimeout(() => {
      window.location.href = '/passport/login?passport='+window.location.href;
    }, 100);
  }

  render() {
    const { item, thisSection } = this.props;
    const { setLoginRoute } = this.props.logProps;

    const lockedText = {
      notice: '当前内容仅限“资料中心”注册用户访问。',
      linkText: '立即注册以获得登录权限',
    }
    let showImage = null;
    if (item.acf.innerImage) {
      showImage = item.acf.innerImage;
    } else if (item.featuredImage) {
      showImage = item.featuredImage;
    }

    let isBlog = null;
    if (
      (thisSection === 'vodka-space') ||
      (thisSection === 'research-institute')
    ) {
      isBlog = true;
    }

    if ( (thisSection === 'videos') || isBlog ) {
      showImage = false;
    }

    let hasProtectedLink = false;
    if (
      (thisSection === "training") 
      || (thisSection === 'product-docs' && item.acf_pd.documentationSlug)
      || (item.acf.fileUrl)
    ) {
      hasProtectedLink = true;
    }
    let bodyText = item.acf.bodyText;
    if (!this.state.bodyLinksAccess) {
      bodyText = bodyText.replace(/<a\b[^>]*>/ig,"").replace(/<\/a>/ig, "");
    }

    let loggedRead = item.acf.loggedRead;
    if (!this.state.bodyLinksAccess) {
      // 貌似会报错
      // loggedRead = loggedRead.replace(/<a\b[^>]*>/ig,"").replace(/<\/a>/ig, "");
    }

    return (
      <div className={`template inner inner-${thisSection}`}>
        <Breadcrumb b3Text={item.title} seo={item.acf_seo} />
        <div className={`content zoom2`}>
          <div className="top">
            <h4>{item.title} <span>{item.acf.subtitle}</span></h4>
            <p>{item.acf.description}</p>
          </div>
          <div className={`main ${thisSection} ${this.state.bodyAccess}`}>
            {showImage &&
              <div className="image">
                <img src={showImage.sourceUrl} alt="" />
                {(this.props.logProps.loggedInUser === null) &&
                  <div className="passport">
                    <p>注册天旦会员，立即体验产品在线demo，并可免费下载产品资料、用户案例、技术白皮书等运维精华资料。</p>
                    <button onClick={() => this.handleOpenLoginPage('页面内容右下角')} >立即登录或注册</button>
                  </div>
                }
              </div>
            }
            {(item.acf_v && item.acf_v.videoUrl) && (this.state.bodyAccess) &&
              <div className="video">
                <video preload="auto" controls>
                  <source src={item.acf_v.videoUrl} type="video/mp4" />
                </video>
              </div>
            }

            {isBlog && 
              <BlogLinks item={item} thisSection={thisSection} />
            }

            <div className="text">

              {(this.state.bodyAccess) && <BodyText bodyText={bodyText}/>}

              {this.state.userAccess === "ok" && <BodyText bodyText={(loggedRead)}/>}
              
              { (hasProtectedLink || !this.state.bodyAccess) && 
                <div className="protected-content">
                  {this.state.userAccess === "loginRestricted" &&
                    <div className="locked">
                      <div className="inner">
                        <Icon name="lock" />
                        <div className="text">
                          <p>{lockedText.notice}</p>
                          <button className="link" onClick={() => this.handleOpenLoginPage('页面内容底部')}>{lockedText.linkText}</button>
                        </div>
                      </div>
                    </div>
                  }
                  {this.state.userAccess === "userRestricted" &&
                    <div className="locked">
                      <div className="inner">
                        <Icon name="lock" />
                        <div className="text">
                          <p>{this.state.userMessage.text}</p>
                          <button className="link" onClick={() => { setLoginRoute('user-access-request-form') }}>{this.state.userMessage.link}</button>
                        </div>
                      </div>
                    </div>
                  }
                  {this.state.userAccess === "ok" &&     
                    <div>
                      {/* {(thisSection === "training") &&
                        <div className="submit-wrap">
                          <button className="button" onClick={() => { setLoginRoute('training-application-form') }}>
                            <Icon name="email" />
                            <p>申请培训</p>
                          </button>
                          <input type="hidden" name="setTrainingSubject" value={item.title} />
                        </div>
                      } */}
                      {((thisSection === 'product-docs' && item.acf_pd.documentationSlug)) &&
                        <>
                          <BodyText bodyText={(loggedRead)}/>
                          <div className="submit-wrap">
                            <a className="button" href={`/product-docs/${item.acf_pd.documentationSlug}`} target="_blank" rel="noopener noreferrer">
                              <Icon name="doc" />
                              <p>查看完整手册</p>
                            </a>
                          </div>
                        </>
                      }
                      {(item.acf.fileUrl) &&
                        <>
                          <BodyText bodyText={(loggedRead)}/>
                          <div className={`submit-wrap ${this.state.loading ? 'loading' : ''}`}>
                            <button className="button" disabled={this.state.loading} onClick={() => { this.downloadFromUrl(item.acf.fileUrl) }}>
                              {/* <Icon name="pdf" /> */}
                              <p>点击下载文件</p>
                            </button>
                            <span className="loader">
                              <ReactSVG src="/images/ui/loader-form.svg" />
                            </span>
                          </div>
                        </>
                      }
                    </div>
                  }             
                </div>
              }

            </div>
          </div>
        </div>

      </div>
    )
  }
}







class BlogLinks extends React.Component {

  render() {

    const { item, thisSection } = this.props;

    const sections = {
      "vodka-space": {
        query: gqlQueries.GET_ALL_VODKA_POSTS,
        data: "vodka_posts"
      },
      "research-institute": {
        query: gqlQueries.GET_ALL_RESEARCH_POSTS,
        data: "research_posts"
      },
    }
    return (
      <Query query={sections[thisSection].query} >
        {({ loading, error, data }) => {
          if (loading) return <div className="loader" />;
          if (error) return <NotFoundPage />
          
          const items = data[sections[thisSection].data].edges;
          return <BlogLinksContent items={items} item={item} />
        }}
      </Query>
    )
  }
}

const BlogLinksContent = (props) => {
  
  const { items, item } = props;
 
  let filteredItems = items.filter(thisItem => {
    return thisItem.node.id !== item.id;
  });
  filteredItems = filteredItems.slice(0, 5);

  return (
    <div className="blog-links">
      <h4>推荐阅读：</h4>
      <ul>
        {
          filteredItems.map((item, i) => {
            return (
              <li key={i}><Link to={item.node.slug}>{item.node.title}</Link></li>
            )
          })
        }
      </ul>
    </div>  
  )
}








export default withRouter(Inner);
