//
import React from 'react';
import axios from 'axios'
import Cookies from 'js-cookie'

//
const Status = (props) => {
    const { addClass, logProps } = props;
    
    const handleLogOut = () => {
        logProps.logOut();
        window.sensors.logout();
        
        // 登出
        Cookies.remove('X-Demo-Token');
        axios.get('https://open.netis.com/php/LoginStatus/status.session.clear.php');
    };

    const handleOpenLoginPage = () => {
      // 埋点：点击注册登录入口
      window.sensors.track('clickEntrance', {
        'referrer_page': window.document.title,
        'source_seat': '页面内容右上角'
      });
      setTimeout(() => {
        window.location.href = '/passport/login?passport='+window.location.href;
      }, 100);
    }

    return (
        <div className={`status ${addClass}`}>
            {logProps.loggedInUser &&
              <div className="logged-in">
                <span className="circle"></span>
                您好，{logProps.loggedInUser.name}。<button className="link" onClick={handleLogOut}>退出登录</button>
              </div>
            }
            {(logProps.loggedInUser === null) &&
              <div className="logged-out">
                {/* 未登录。<button className="link" onClick={() => { logProps.setLoginRoute('log-in', { 'source_seat': '页面内容右上角' }) }}>登录或注册</button> */}

                {/* url里带next参数跳转到祖册登录 */}
                未登录。<button className="link" onClick={handleOpenLoginPage} >登录或注册</button>
              </div>
            }
        </div>
    )
}

export default Status
