const env = {
  local: {
    wpGraphQLUrl: 'https://open-netis.lanternbox.com/graphql',
    wpRestAPIUrl: 'https://open-netis.lanternbox.com/wp-json/wp/v2/',
    phpUrl: 'http://netis-php:8888',
    isMobileLive: true,
    isEmailLive: false
  },
  dev: {
    wpGraphQLUrl: 'https://wp-open.netis.com/graphql',//本番
    // wpGraphQLUrl: 'https://test.124115.com/graphql',
    // wpGraphQLUrl: 'https://wp-open.htmlbox.org/graphql',//oy
    
    wpRestAPIUrl: 'https://wp-open.netis.com/wp-json/wp/v2/',
    phpUrl: 'https://www.netis.com/php',
    isMobileLive: true,
    isEmailLive: true
  },
  live: {
    wpGraphQLUrl: 'https://wp-open.netis.com/graphql',//本番
    // wpGraphQLUrl: 'https://test.124115.com/graphql',
    // wpGraphQLUrl: 'https://wp-open.htmlbox.org/graphql',//oy
    wpRestAPIUrl: 'https://wp-open.netis.com/wp-json/wp/v2/',
    phpUrl: 'https://www.netis.com/php',
    isMobileLive: true,
    isEmailLive: true
  }
};



const thisEnv = env.live;

export default thisEnv;



