import React from "react";

class Team extends React.Component {
    //
    render() {
        //
        return ( 
            <div className="team bg-white">
                <div className="title">服务团队</div>
                <div className="text">
                    <div>联系电话:</div>
                    <div>400-820-6509 </div>
                    <div>联系邮箱:</div>
                    <div>CustomerSuccess@netis.com </div>
                    <div>在线工单系统:</div>
                    <div>
                        <img alt="" src="/static/img/work_order.png" />
                    </div>
                </div>
            </div>
        )
    }
}

export default Team;
