//
import axios from 'axios';
import Env from '../config/env.js';


class UserModel {
    //
    static collect(articleId, status, article) 
    {
        //
        // const s = this.getVip();
        // console.log(s);


        //
        const userId = this.getId();
        if (userId === 0) {
            return Promise.reject('error');
        }
        /*
        if (this.getVip() !== 'k') {
            return Promise.reject('error');
        }
        */

        //
        const options = {
            user_id: userId,
            article_id: articleId
        }

        //
        // const url = 'http://api.test.124115.com/collect';
        const url = `${Env.base}/collect`;
        console.log(options);
        if (status === 1) {
            // 埋点：点击收藏
            window.sensors.track('ClickCollect', {
                'referrer_page': window.document.title,
                'product': article.bname,
                'category': article.cname,
                'article_title': article.title
            });
            return axios({
                url: url,
                method: 'post',
                data: options,
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
        } else {
            // 埋点：取消收藏
            window.sensors.track('ClickCollectCancel', {
                'referrer_page': window.document.title,
                'product': article.bname,
                'category': article.cname,
                'article_title': article.title
            });
            return axios({
                url: url,
                method: 'delete',
                data: options
            });
        }
    }

    //
    static getId() 
    {
        // return 270;
        //
        const jsonStr = localStorage.getItem('loggedInUser');
        // {"name":"f g","company":"上海","position":"test","email":"test@qq.com","mobile":"13812911641","userLevel":"nullUser"}

        //
        if (jsonStr == null) {
            return 0;
        }

        //
        const options = JSON.parse(jsonStr);
        return options.id;
        // return localStorage.setItem('loggedInUser', JSON.stringify(user))
    }

    static getVip() 
    {
        // return 270;
        //
        const vip = localStorage.getItem('vip');
        /*
        console.log(vip);
        if (vip == null) {
            console.log('null');
        } else {
            console.log('##');
        }
        */

        // {"name":"f g","company":"上海","position":"test","email":"test@qq.com","mobile":"13812911641","userLevel":"nullUser"}

        //
        if (vip == null) {
            return 'l';
        }

        return vip;
        // return localStorage.setItem('loggedInUser', JSON.stringify(user))
    }

    static processVipStatus() 
    {
        // const jsonStr = localStorage.getItem('loggedInUser');
        // const options = JSON.parse(jsonStr);

        //
        const id = this.getId();
        const url = `${Env.base}/user/${id}/vip`;
        axios({
            url: url,
            method: 'get',
        })
        .then(res => {
            const vip = res.data === 1 ? 'k' : 'l';
            // options.vip = vip;
            localStorage.setItem('vip', vip);
        });
    }
}

export default UserModel;