import React from "react";
import { NavLink } from 'react-router-dom';

//
class TagComponent extends React.Component {
    //
    constructor(props) {
        super(props);

        const { tags } = props;

        this.state = {
            tags: tags
        };

        // this.collect = this.collect.bind(this);
    }

    componentWillReceiveProps(props) {
        //
    }

    //
    componentWillMount() {
        // console.log('will mount');
    }

    //
    componentDidMount() {
        //
        // console.log('did');
    }

    //
    componentWillUnmount() {
        // console.log('will unmount');
    }

    //
    render() {
        //
        const tags = this.state.tags;

        //
        let items = '';
        if (tags.length <= 0) {
            items = ' -';
        } else {
            items = tags.map(tag => 
                <NavLink key={tag.id} to={'/knowledgebase/tag/' + tag.id}>{tag.name}</NavLink>
            );
        }

        return (
            <span className="tag">标签：{items} </span>
        )
    }
}

export default TagComponent;
