import React, { Component } from 'react'


class UserAccessRequestConfirmation extends Component {

  render() {
    const logProps = this.props.logProps;
    const { setLoginRoute } = logProps;
    return (
      <div className="form-holder confirmation">
        <span className="close-x" onClick={ () => setLoginRoute(null) }></span>
        <h4>申请已提交</h4>
        <p>请保持手机畅通，我们将在1个工作日内与您取得联系，完成身份核实。如您有任何疑问，请联系我们：021-58700166.</p>
        <div className="submit-wrap">
          <button onClick={ () => { setLoginRoute(null) }}>关闭</button>
        </div>
      </div>
    )
  }
}

export default UserAccessRequestConfirmation









