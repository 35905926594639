//
import axios from 'axios';
//
import Env from '../config/env.js';
//
import UserModel from '../model/user.js';

//
class SearchModel
{
    //
    static getArticle(options) 
    {
        //
        const userId = UserModel.getId();
        options.user_id = userId;

        //
        // const that = this;
        const url = `${Env.base}/search`;
        //
        return axios({
            url: url,
            method: 'get',
            params: options
        });
    }
}

export default SearchModel;