//
import axios from 'axios';
import Env from '../config/env.js';

//
class StatisticsModel {
    //
    static get(brandId) 
    {
        //
        // const that = this;
        const url = `${Env.base}/statistics/${brandId}`;
        //
        return axios({
            url: url,
            method: 'get'
        });
    }
}

export default StatisticsModel;