import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Breadcrumb from 'components/Breadcrumb';

class Table extends React.Component {
  
  render() {
    const baseUrl = this.props.match.url;
    const items = this.props.data;

    return ( 
      <div className="template table">
        <Breadcrumb />
        <div className="items">
          { items.map(edge => {
            return (
              <Item key={edge.node.id} item={edge.node} baseUrl={baseUrl} logProps={this.props.logProps} />
            );
          })}
        </div>
      </div>
    )
  }  
}




const Item = (props) => {
  const { item, baseUrl } = props;
  // const { loggedIn } = props.logProps;
  return (
    <div className="item">
      <div className="one"><h4><NavLink className="link blue" to={`${baseUrl}/${item.slug}`}>{item.title}</NavLink></h4></div>
      <div className="two"><h4><span>{item.acf.subtitle}</span></h4></div>
      <div className="three">{item.acf.description}</div>
      <div className="spacer"></div>
      <div className="link">        
        <NavLink className="link more" to={`${baseUrl}/${item.slug}`}>查阅 »</NavLink>
      </div>
    </div>
  )
}


export default withRouter(Table);

