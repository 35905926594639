import React from "react";
// import { NavLink } from 'react-router-dom';
// module 

// import Tag from 'pages/summary/module/tag.js';
import Tag from 'pages/summary/home/tag.js';

//
import Foot from 'pages/summary/module/foot.js';
import Entrance from 'pages/summary/home/entrance.js';
import HomeModel from 'pages/summary/home/model.js';
import Team from 'pages/summary/module/team.js';
// 
import Articles from 'components/summary/articles.js';
import ArticleModel from 'model/article.js';
import TagModel from 'model/tag.js';
import BaseView from '../BaseView';

class Home extends BaseView {
    //
    constructor(props) 
    {
        //
        super(props);

        //
        this.state = {
            // for search action
            keyword: '',
            // brandId: 0,
            categoryId: 0,
            // 当前显示的brand
            brandId: 1,
            //
            articles:[],
            // 
            cache:{
                1: [],
                2: [],
                3: []
            },
            tags:[],
            cacheTags: {
                1: [],
                2: [],
                3: []
            }
        };
        
        //
        this.keywordChange = this.keywordChange.bind(this);
        this.brandChange = this.brandChange.bind(this);
        this.categoryChange = this.categoryChange.bind(this);
        this.search = this.search.bind(this);

        //
        this.changeBrand = this.changeBrand.bind(this);

        //
        this.getArticle(1);
        this.getArticle(2);
        this.getArticle(3);

        //
        this.getTag(1);
        this.getTag(2);
        this.getTag(3);

        //
        localStorage.setItem('brand_id', 0);
    }

    //
    componentWillReceiveProps(props) {
    }

    //
    componentWillMount() {
    }

    //
    componentWillUnmount() {
    }
    //
    componentDidMount() {
        this.buryingPoint('天旦Netis | 服务知识库 | 首页');
    }

    getArticle(brandId) {
        const that = this;
        //
        ArticleModel.hot(brandId)
        .then(function(res) {
            //
            that.state.cache[brandId] = res.data;
            // 
            if (brandId === 1) {
                that.setState({
                    articles:res.data,
                });
            }
            //
            
        }); 
    }

    getTag(brandId) {
        const that = this;
        //
        TagModel.getTag(brandId)
        .then(function(res) {
            //
            that.state.cacheTags[brandId] = res.data;
            // 
            if (brandId === 1) {
                that.setState({
                    tags:res.data,
                });
            }
            //
            
        }); 
    }

    //
    keywordChange(event) 
    {
        this.setState({
            keyword: event.target.value
        });
    }
    //
    brandChange(event) 
    {
        this.setState({
            brandId: event.target.value
        });
    }
    //
    categoryChange(event) 
    {
        this.setState({
            categoryId: event.target.value
        });
    }
    //
    search() 
    {
        const brandId = this.state.brandId;
        const categoryId = this.state.categoryId;
        const keyword = this.state.keyword;

        // 埋点：点击搜索按钮
        window.sensors.track('search_start', {
            'referrer_page': window.document.title,
            'search_keyword': keyword
        });

        if (!keyword) {
            return;
        }

        const url = `/knowledgebase/search/${brandId}/${categoryId}/${keyword}`;
        this.props.props.history.push(url);
    }

    // change brand id

    changeBrand(brandId) 
    {
        //
        localStorage.setItem('brand_id', brandId);

        //
        const that = this;
        this.setState({
            brandId: brandId,
            articles: that.state.cache[brandId],
            tags: that.state.cacheTags[brandId]
        });
    }

    //
    render() {
        // 
        const brandId = this.state.brandId;
        const articles = this.state.articles;
        const history = this.props.props.history;
        const tags = this.state.tags;
        //
        const { setLoginRoute } = this.props;
    
        //
        return ( 
            <div>    
                <div className="container-fluid bg-white">   
                    <div className="container">
                        <div className="home">
                            <div className="home-title cursor-pointer">服务知识库</div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-white">
                    <div className="container">
                        <div className="search form-group">
                            <select className="product form-control" value={this.state.brandId} onChange={this.brandChange}>
                                <option value="0">选择产品类型</option>
                                <option value="1">BPC</option>
                                <option value="2">NPM</option>
                                <option value="3">PACKET AGENT</option>
                            </select>
                            <select className="content form-control" value={this.state.categoryId} onChange={this.categoryChange}>
                                <option value="0">选择内容类型</option>
                                <option value="1">深度知识</option>
                                <option value="2">问题排查</option>
                                <option value="3">最佳实践</option>
                                <option value="4">配置介绍</option>
                                <option value="5">经典案例</option>
                            </select>
                            <input className="value form-control" type="text" value={this.state.keyword} onChange={this.keywordChange} />
                            <button className="bg-blue" onClick={this.search}>搜索</button>
                        </div>
                    </div>
                </div>
                    
                <div className="container-fluid">
                    <div className="container">
                        <div className="categories">
                            <div className={brandId === 1 ? 'active category cursor-pointer' : 'category cursor-pointer'} onClick={this.changeBrand.bind(this, 1)}>BPC</div>
                            <div className={brandId === 2 ? 'active category cursor-pointer' : 'category cursor-pointer'} onClick={this.changeBrand.bind(this, 2)}>NPM</div>
                            <div className={brandId === 3 ? 'active category cursor-pointer' : 'category cursor-pointer'} onClick={this.changeBrand.bind(this, 3)}>Packet Agent</div>
                        </div>
                    </div>
                </div>
        
                <div className="container-fluid">
                    <div className="container">
                        <div className="primary">
                            <div className="content bg-white">
                                <div className="main-title text-green">最热知识推荐</div>
                                <Articles history={history} articles={articles} setLoginRoute={setLoginRoute} />
                            </div>
                            <div className="side">
                                <Tag tags={tags} />
                                <Entrance brandId={brandId} history={history} />
                                <Team />
                            </div>
                        </div>
                    </div>
                </div>

                <HomeModel brandId={brandId} />

                <Foot />
            </div>
        )
    }
}

export default Home;
