//

//
class CategoryModel 
{
    //
    static getName(id)
    {
        //
        id = parseInt(id);
        let title = '';
        switch (id) {
            case 1:
                title = ' | 深度知识';
                break;
            case 2:
                title = ' | 问题排查';
                break;
            case 3:
                title = ' | 最佳实践';
                break;
            case 4:
                title = ' | 配置介绍';
                break;
            case 5:
                title = ' | 经典案例';
                break;
            case 6:
                title = ' | 我的收藏';
                break;
            case 7:
                title = ' | 最新知识';
                break;
            case 8:
                title = ' | 热门知识';
                break;
            default:
                break;
        }

        return title;
    }

    static getCleanName(id)
    {
        //
        id = parseInt(id);
        let title = '';
        switch (id) {
            case 1:
                title = '深度知识';
                break;
            case 2:
                title = '问题排查';
                break;
            case 3:
                title = '最佳实践';
                break;
            case 4:
                title = '配置介绍';
                break;
            case 5:
                title = '经典案例';
                break;
            case 6:
                title = '我的收藏';
                break;
            case 7:
                title = '最新知识';
                break;
            case 8:
                title = '热门知识';
                break;
            default:
                break;
        }

        return title;
    }
}

export default CategoryModel;
