import React from "react";
import Grid from "templates/Grid";
import { Query } from 'react-apollo';
import gql from 'graphql-tag';


const GET_TRAININGS = gql`
  query($first: Int) {
    trainings(first: $first) {
      edges {
        node {
          id 
          title
          slug
          featuredImage {
            sourceUrl
          }
          acf {
            subtitle
            description
          }
        }
      }
    }
  }
`;


class Training extends React.Component {
  render() {
    return ( 
      <div>
        <Query query={GET_TRAININGS} variables={{ first: 100 }}>
          {({ loading, error, data }) => {
            if (loading) return <div className="loader" />;
            if (error) return <p>Error :(</p>;
            if (!data.trainings.edges.length) return <p>No matching posts found.</p>;
            let items = data.trainings.edges;
            return <Grid data={items} logProps={this.props.logProps} />
          }}
        </Query>
      </div>
    )
  }
}

export default Training;
