import React from "react";
// 
import { NavLink } from 'react-router-dom';
// module
// import TagModel from 'model/tag.js';

class Tag extends React.Component {
    //
    constructor(props) {
        super(props);
    }

    //
    /*
    componentDidMount() {
        // console.log('tag componentDidMount');
        //
        const that = this;
        TagModel.getTag()
        .then(function(res) {
            that.setState({
                tags: res.data
            });
        });
    }
    */

    //
    render() {
        const { tags } = this.props;
        //
        const items = tags.map(tag => 
            <div className="tag" key={tag.id}>
                <NavLink to={'/knowledgebase/tag/' + tag.id}>{tag.name}</NavLink>
            </div>
        );

        //
        return ( 
            <div className="tags bg-white">
                <div className="title">最热标签推荐</div>
                <div>
                {items}
                </div>
            </div>
        )
    }
}

export default Tag;
