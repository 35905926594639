import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Container from 'components/Container';
import Menu from 'components/Menu';
import ScrollToTop from 'components/ScrollToTop';


class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
      lastScrollY: 0
    };
    // this.topTrue = this.topTrue.bind(this);
    // this.topFalse = this.topFalse.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.smartMenu = this.smartMenu.bind(this);
    this.addIE11Class = this.addIE11Class.bind(this);
  }


  addIE11Class() {
    if (window.navigator.userAgent.indexOf('Trident') > -1) {
      document.body.classList.add('is-ie');
    }
  }
  componentDidMount() {
    // window.addEventListener('scroll', this.handleScroll);
    this.addIE11Class();
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.handleScroll);
  }


  // topTrue() {
  //   this.setState({ isTop: true });
  // }
  // topFalse() {
  //   this.setState({ isTop: false });
  // }

  handleScroll() {
    // if (window.scrollY <= 100) {
    //   this.topTrue();
    // } else {
    //   this.topFalse();
    // }
    window.requestAnimationFrame(this.smartMenu);
  }



  smartMenu() {
    const body = document.body;
    const delta = 5;
    const thisScrollY = window.scrollY; // new variable = distance from top of screen
    const scrollDistance = thisScrollY - this.state.lastScrollY;
    if (Math.abs(scrollDistance) <= delta) // if the difference since last scrolling is less than 5,
      return; // do nothing
    if (body.classList.contains('menu-stick')) return;
    if (thisScrollY < 200) {
      body.classList.remove('menu-up');
      return;
    }
    if (scrollDistance > 0) { // if scrolling down,
      body.classList.add('menu-up');
    } else { // if scrolling up,
      body.classList.remove('menu-up');
    }
    if (window.innerHeight + thisScrollY >= body.offsetHeight) { // hit bottom of page
      body.classList.remove('menu-up');
    }
    this.setState({ lastScrollY: thisScrollY });
  }


  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <div>
            <Menu />
            <Container />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    )
  }
}

export default AppRouter;
