import React from "react";
// 
import { NavLink } from 'react-router-dom';
// module
import TagModel from 'model/tag.js';

class Tag extends React.Component {
    //
    constructor(props) {
        super(props);

        this.state = {
            tags: []
        };
    }

    //
    componentDidMount() {
        // console.log('tag componentDidMount');
        //
        const that = this;
        const { brandId } = this.props;
        console.log(this.props);
        TagModel.getTag(brandId)
        .then(function(res) {
            that.setState({
                tags: res.data
            });
        });
    }

    //
    render() {
        //
        const items = this.state.tags.map(tag => 
            <div className="tag" key={tag.id}>
                <NavLink to={'/knowledgebase/tag/' + tag.id}>{tag.name}</NavLink>
            </div>
        );

        //
        return ( 
            <div className="tags bg-white">
                <div className="title">最热标签推荐</div>
                <div>
                {items}
                </div>
            </div>
        )
    }
}

export default Tag;
