//

//
class BrandModel 
{
    //
    static getName(id)
    {
        //
        id = parseInt(id);
        let title = '';
        switch (id) {
            case 1:
                title = ' | BPC ' + title;
                break;
            case 2:
                title = ' | NPM ' + title;
                break;
            case 3:
                title = ' | Packet Agent ' + title;
                break;
            default: 
                break;
        }

        return title;
    }

    static getCleanName(id)
    {
        //
        id = parseInt(id);
        let title = '';
        switch (id) {
            case 1:
                title = 'BPC ';
                break;
            case 2:
                title = 'NPM';
                break;
            case 3:
                title = 'Packet Agent';
                break;
            default: 
                break;
        }

        return title;
    }
}

export default BrandModel;