import React from "react";
import { NavLink } from 'react-router-dom';

class Subnav extends React.Component {
    //
    constructor(props) {
        super(props);

        //
        const { brandId,categoryId } = props;
        this.state = {
            brandId: parseInt(brandId),
            categoryId: parseInt(categoryId)
        };
    }

    componentWillReceiveProps(props){
        const { brandId,categoryId } = props;
        this.setState({
            brandId: parseInt(brandId),
            categoryId: parseInt(categoryId)
        });
    }

    //
    render() {
        const brandId = this.state.brandId;
        //
        const categoryId = this.state.categoryId;
        const brandName = brandId === 1 ? 'bpc' 
            : brandId === 2 ? 'npm' 
            : 'packet-agent';
        
        //
        return ( 
            <div className="container-fluid">
                <div className="container">
                    <div className="categories">
                        <NavLink to={'/knowledgebase/latest/' + brandName} className={ categoryId === 7 ? 'active category' : 'category'}>
                            最新知识
                        </NavLink>

                        <NavLink to={'/knowledgebase/hot/' + brandName} className={ categoryId === 8 ? 'active category' : 'category'}>
                            热门知识
                        </NavLink>

                        <NavLink to={'/knowledgebase/category/' + brandName + '/indepth'} className={ categoryId ===1 ? 'active category' : 'category'}>
                            深度知识
                        </NavLink>
                        <NavLink to={'/knowledgebase/category/' + brandName + '/troubleshooting'} className={ categoryId === 2 ? 'active category' : 'category'}>
                            问题排查
                        </NavLink>
                        <NavLink to={'/knowledgebase/category/' + brandName + '/practise'} className={ categoryId === 3 ? 'active category' : 'category'}>
                            最佳实践
                        </NavLink>
                        <NavLink to={'/knowledgebase/category/' + brandName + '/configuration'} className={ categoryId === 4 ? 'active category' : 'category'}>
                            配置介绍
                        </NavLink>
                        <NavLink to={'/knowledgebase/category/' + brandName + '/cases'} className={ categoryId === 5 ? 'active category' : 'category'}>
                            经典案例
                        </NavLink>

                        <NavLink to={'/knowledgebase/collect/' + brandName} className={ categoryId === 6 ? 'active category' : 'category'}>
                            我的收藏
                        </NavLink>
                    </div>
                </div>
            </div>
        )
    }
}

export default Subnav;
