import React from 'react'
import axios from 'axios'

function CookiesPolicy() {
    console.log('CookiesPolicy');
    return (
        <div className="cookies_policy">
            <div className="wrapper">
                <h2>您的Cookies偏好</h2>
                <p>
                欢迎来到天旦Netis！
                <br/><br/>
                我们希望使用分析型Cookies和类似技术 (“Cookies”) 来改善我们的网站。 
                Cookies收集的信息不会识别您个人。
                有关我们使用的Cookies的类型以及您的偏好选项（包括如何更改您的偏好设置）的更多信息，请查看此处的<a href="https://www.netis.com/privacy/">Cookies政策</a>。
                </p>
                <div className="btns">
                    <div className="btn_accept" onClick={()=>{
                        localStorage.setItem('cookies_policy',Date.parse(new Date()));
                        document.querySelector('.cookies_policy').classList.remove('open');
                        document.querySelector('.cookies_policy').classList.add('close');

                        // 后段埋点/
                        axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
                            distinct_id: 'php_'+Date.parse(new Date()),
                            event_name: 'CookiesPolicyResult',
                            datas: {
                            'cookies_policy': '接受所有Cookies',
                            }
                        }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
                        // /后段埋点
                    }}>接受所有Cookies</div>
                    <div className="btn_refuse" onClick={()=>{
                        document.querySelector('.cookies_policy').classList.remove('open');
                        document.querySelector('.cookies_policy').classList.add('close');

                        // 后段埋点/
                        axios.post('https://netis.com/php/sensorsdata/open.netis.php',{
                            distinct_id: 'php_'+Date.parse(new Date()),
                            event_name: 'CookiesPolicyResult',
                            datas: {
                            'cookies_policy': '稍后再说接受所有Cookies',
                            }
                        }).then((res)=>{console.log(JSON.stringify(res.data))}).catch((error)=>{console.log(error)})
                        // /后段埋点
                    }}>稍后再说</div>
                </div>
            </div>
        </div>
    )
}

export default CookiesPolicy
