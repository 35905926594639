import React from "react";

class Entrance extends React.Component {
    //
    constructor(props) 
    {
        //
        super(props);

        //
        const { brandId,history } = props;
        this.state = {
            brandId: brandId,
            history: history
        };

        this.turnTo = this.turnTo.bind(this);
    }

    componentWillReceiveProps(props) 
    {
        // console.log('will');
        const { brandId } = props;
        this.setState({
            brandId: brandId
        });
    }

    turnTo() 
    {
        //
        const brandId = this.state.brandId;
        //
        localStorage.setItem('brand_id', brandId);
        //
        const brandName = brandId === 1 
            ? 'bpc' 
            : brandId === 2 
                ? 'npm' : 'packet-agent';
        const url = '/knowledgebase/latest/' + brandName;
        const history = this.state.history;
        // console.log('2122');
        // console.log(history);
        history.push(url);
    }

    //
    render() {
        //
        const brandId = this.state.brandId;
        //
        const brandName = brandId === 1 
            ? 'BPC' 
            : brandId === 2 
                ? 'NPM' 
                : 'Packet Agent';

        //
        return ( 
            <div className="_entrance bg-white cursor-pointer">
            <div onClick={this.turnTo}>
                <div className="entrance d-flex">
                <img alt="" src="/static/img/icon6.png" />
                <span>进入{brandName}知识库</span>
                </div>
           </div>
           </div>
        )
    }
}

export default Entrance;
